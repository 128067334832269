   <template>
  
  <div class="container mx-auto">
 
    <ArbolDivisiones v-if="loadTree" :nombreFaena="nombreFaena" :datadiv="dataDivisionesFaena" :trenJaulas="trenJaulas" @ampliaGrafico="ampliaGrafico" @exitArbol="exitArbol"></ArbolDivisiones>
    <MedicionDivisionAmpliado v-if="loadMediciones" :objDivisionAmpliar="objDivisionAmpliar" @exitGrafico="exitGrafico" :nombreFaena="nombreFaena" ></MedicionDivisionAmpliado>

  </div>

</template>
<script>

import axios from "axios";
import ArbolDivisiones from "./ArbolDivisiones";
import MedicionDivisionAmpliado from "./MedicionDivisionAmpliado"
export default {
  name: "DivisionesCliente",
  props: ["idEmp", "idFaena", "nombreFaena"],
  emits: ["chgDivision","trenJaulas"],
  components: {
    ArbolDivisiones, MedicionDivisionAmpliado
  },

  data() {
    return {
      divisiones: [{}],
      dataDivisionesFaena: [{}],
      currentDivision: [{}],
      division: 0,
      objDivisionAmpliar: {},
      componentKey: 0,
      loadTree: false,
      loadMediciones:false,
      loadVistaplanta:false,
      idEmpresa: this.idEmp,
      trenJaulas: Object
    };
  },
  methods: {

    exitArbol() {
      this.$emit("chgDivision", false);
    },
    onNodeClick(node) {
      this.estGrafico = 0
      this.division = node.id
      this.componentKey = node


    },
    exitGrafico()
    {
      this.loadTree = true
      this.loadMediciones=false
      this.loadVistaplanta=false
    },
    ampliaGrafico(objDivision) {
      if (!objDivision) return false;
      //console.log("SE AMPLIA JAULA", objDivision.id);
      this.loadTree = false
      this.loadMediciones = true
      this.loadVistaplanta=false
      //this.idDivisionAmpliar = objDivision.id
      //this.nombreDivisionAmpliar = objDivision.nombre
      this.objDivisionAmpliar = objDivision
    },


    async getDivisionesApi() {
      //console.log(this.idEmpresa, "id empresa")
      try {
        const resp =
          await axios.get(
            //"http://localhost:8080/api/Faenas/GetFaenasToJaulasByEmpresaJsonTree?idEmpresa=" + this.idEmpresa+"&idFaena="+this.idFaena
              "http://localhost:8080/api/Faenas/GetFaenasToJaulasByEmpresaByIdFaenaJsonTree?idEmpresa=" + this.idEmpresa+"&idFaena="+this.idFaena
          );
        this.dataDivisionesFaena = resp.data;
        this.loadTree = true
        this.loadMediciones=false
        this.loadVistaplanta=false

        let trenesObject = [{ "idTren": "", "jaulas": "","jaulasnombres":"" }];
        for (var key in resp.data) {
      
          let nodo = resp.data[key]['nodes'];
          for (var nkey in nodo) {
            let subnodo = nodo[nkey]['nodes'];
          //  console.log(subnodo)
            for (var keytren in subnodo) {
              trenesObject.push({ "idTren": subnodo[keytren]['idTren'], 
                                "jaulas": '', 
                                "jaulasnombres": '',
                                "divisionPadre":nodo[nkey]['label']+" / "+subnodo[keytren]['label'] })
              let nodojaulas = subnodo[keytren]['nodes']; // aqui están anidadas los 'hijos', es decir las jaulas
              for (var keyjaulas in nodojaulas) {
                // asocia las jaulas a un ID de Tren
                let objIndex = trenesObject.findIndex((obj => obj.idTren == nodojaulas[keyjaulas]['idTren']));
                if (objIndex > 0) {
                  trenesObject[objIndex].jaulas += nodojaulas[keyjaulas]['idJaula'] + ","
                  trenesObject[objIndex].jaulasnombres += nodojaulas[keyjaulas]['label'] + ","
                }
              }
            }
          }
           

        }

        
        this.trenJaulas=trenesObject;
        
      } catch (err) {
        console.log(err);
      }
    },
    async getDivisionesApi_version_iterativa() {
    //  console.log(this.idEmpresa, "id empresa")
      try {
        const resp =
          await axios.get(
            "http://localhost:8080/api/Faenas/GetFaenasToJaulasByEmpresaJsonTree?idEmpresa=" + this.idEmpresa
          );
        this.dataDivisionesFaena = resp.data;
        this.loadTree = true
        this.loadMediciones=false
        this.loadVistaplanta=false
        let trenesObject = [{ "idTren": "", "jaulas": "" }];
        for (var key in resp.data) {

          for (var key1 in resp.data[key]) {
            // faena
            if (key1 === 'nodes') {
              let nodo = resp.data[key][key1];
              for (var nkey in nodo) {
                for (var nkey1 in nodo[nkey]) {
                  // division

                  if (nkey1 === 'nodes') {
                    let subnodo = nodo[nkey][nkey1];
                  
                    for (var keytren in subnodo) {
                      for (var nkey2 in subnodo[keytren]) {
                        // tren

                        if (nkey2 == "idTren") {
                          trenesObject.push({ "idTren": subnodo[keytren][nkey2], "jaulas": '' })
                        }
                        //if (nkey2 == "idTren") {
                        //  console.log(nkey2, subnodo[keytren][nkey2], "<--- tren")
                        //}
                        // if (nkey2 == "idTren") {
                        //  idTren = subnodo[keytren][nkey2];

                        //}

                        if (nkey2 === 'nodes') {

                          let nodojaulas = subnodo[keytren][nkey2];
                          for (var keyjaulas in nodojaulas) {

                        //    console.log("tren:", nodojaulas[keyjaulas]['idTren']);
                        //    console.log("jaula:", nodojaulas[keyjaulas]['idJaula'])
                            let objIndex = trenesObject.findIndex((obj => obj.idTren == nodojaulas[keyjaulas]['idTren']));
                            if (objIndex > 0) {
                              trenesObject[objIndex].jaulas += nodojaulas[keyjaulas]['idJaula'] + ","
                            }
                            // trenesObject[nodojaulas[keyjaulas]['idTren']] += nodojaulas[keyjaulas]['idJaula'] + ","
                            // for (var keyjaula in nodojaulas[keyjaulas]) {
                            //     console.log(keyjaula,":",nodojaulas[keyjaulas][keyjaula] ,"<--")

                            // 
                            // }  
                           // console.log("-----------");
                          }

                          // jaulas
                          //let nodojaulas = subnodo[keytren][nkey2];

                          //  for (var keyjaulas in nodojaulas) {
                          //   if(nodojaulas[keyjaulas]['idTren']=="3"){
                          //         console.log(trenesObject[nodojaulas[keyjaulas]['idTren']] ,":",  nodojaulas[keyjaulas]['idJaula'])
                          //    }
                          //   trenesObject[nodojaulas[keyjaulas]['idTren']] += nodojaulas[keyjaulas]['idJaula'] + ","
                          // }

                        }

                      }

                      // console.log(keyfinal,subnodo[nkey1][keyfinal],"<---")
                    }

                  }


                }
              }
              //--------------------------
            }

          }

          //for (var key1 in resp.data[key]) {
          // console.log(resp.data[key][key1],"<---")
          //}
        }

      

      } catch (err) {
        console.log(err);
      }
    },
  },
  setup() {




    return {


    };




  },
  created() {

  },
  mounted() {
    this.getDivisionesApi();
    this.estGrafico = 0;

  }


};
</script>
 