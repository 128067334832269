<template>
  <div v-if="this.showModalAyuda" id="panelayuda" class=" relative z-10" aria-labelledby="slide-over-title" role="dialog"
    aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>


    <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden ">
        <div class="pointer-events-none fixed inset-y-0 right-0 flex  pl-10 max-w-screen-sm">
          <div class="pointer-events-auto  w-screen   max-w-2xl">
            <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
              <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                <div class="flex items-center justify-between">
                  <div class="">

                  </div>









                  <div className="grid grid-cols-1 grid-rows-1 gap-2 ml-2">

                    <div class="pl-12">
                      <h2 class="text-1xl font-bold tracking-tight text-white sm:text-4xl"> AYUDA
                      </h2>
                    </div>
                  </div>



                  <div>









                  </div>


                  <div class="ml-3 flex h-7 items-center">
                    <button @click="close()" type="button"
                      class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white ">
                      <span class="sr-only">Close panel</span>
                      <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                        aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                      </svg>
                    </button>
                  </div>
                </div>

              </div>
              <div className="p-8">

                <table className="table border-collapse">

                  <tbody>
                    <tr>
                      <td class="h-12 border-slate-300 text-center p-2">
                        Vista en vivo del centro y detalle de cada jaula

                      </td>
                    </tr>
                    <tr>
                      <td class="border ">
                        <img src="../../assets/diagrama_menuayuda.png"   align="center" />


                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table-fixed   border-slate-400">
                  <tbody>
                    <tr>
                      <td colspan="3" class="h-12 border-slate-300 text-center p-2">

                        Estados
                      </td>

                    </tr>
                    <tr>
                      <td width="20%" class="border h-12 border-slate-300 text-center p-2">
                        <span
                          class="inline-flex items-center rounded-full bg-green-600 px-2.5 py-2.5 text-xs font-medium text-green-800"></span>
                      </td>
                      <td style="width:20%" class="border h-12 border-slate-300 p-2">
                        Activo
                      </td>
                      <td class="border h-12 border-slate-300 p-2">
                        Sensor se encuentra posicionado en la jaula, operativo y enviando datos al servidor.
                      </td>
                    </tr>
                    <tr>
                      <td class="border h-12 border-slate-300 text-center p-2">
                        <span
                          class="inline-flex items-center rounded-full bg-red-600 px-2.5 py-2.5 text-xs font-medium text-gray-800"></span>
                      </td>
                      <td class="border h-12 border-slate-300 p-2">
                        Falla Sensor
                      </td>
                      <td class="border h-12 border-slate-300 p-2">
                        Sensor defectuoso, no se reciben datos de esta jaula en el servidor.
                      </td>
                    </tr>
                    <tr>
                      <td width="10%" class="border h-12 border-slate-300 text-center p-2">
                        <span
                          class="inline-flex items-center rounded-full bg-yellow-200 px-2.5 py-2.5 text-xs font-medium text-white"></span>
                      </td>
                      <td class="border h-12 border-slate-300 p-2">
                        Bajo O<sub>2</sub>
                      </td>
                      <td class="border h-12 border-slate-300 p-2">
                        Nivel de oxígeno en la jaula se encuentra bajo el límite aceptable.
                      </td>
                    </tr>
                    <tr>
                      <td class="border h-12 border-slate-300 p-2">

                        <span
                          class="inline-flex items-center rounded-full bg-orange-600 px-2.5 py-2.5 text-xs font-medium text-white"></span>
                      </td>
                      <td class="border h-12 border-slate-300 p-2">
                        Alto ORP
                      </td>
                      <td class="border h-12 border-slate-300 p-2">
                        Nivel de ORP en la jaula se encuentra sobre el límite aceptable.
                      </td>
                    </tr>
                    <tr>
                      <td class="border h-12 border-slate-300 p-2">
                        <span
                          class="inline-flex items-center rounded-full bg-gray-400 px-2.5 py-2.5 text-xs font-medium text-white"></span>
                      </td>
                      <td class="border h-12 border-slate-300 p-2">
                        Inactivo
                      </td>
                      <td class="border h-12 border-slate-300 p-2">
                        Jaula no se encuentra equipada con el sistema Oxyplus.
                      </td>
                    </tr>

                  </tbody>
                </table>

                <div class="p-12"></div>






              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>




export default {
  props: {
    nomedificio: String,
    showModalAyuda: Boolean
  },
  methods: {
    close() {

      this.$emit("close")
    },
    shownom(v) {
      console.log(v);
    }
  },
  data() {
    return {
      showPanel: Boolean


    }
  },
  created() {
    console.log('holA!!')
    this.showPanel = this.showModalAyuda
  }

}
</script>