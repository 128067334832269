// divisiones.js
import axios from "axios";

const state = {
    userLogin: Object,
    statusLogin:0,
    tokenLogin:''
 
  };
  const getters = {
    getUserPreferenceItems: (state) => {
      return state.userLogin.info.preference.items;
    }
  }
  const mutations = {
    setUser(state, user) {
      state.userLogin = user;
    },
    setStatusLogin(state, status) {
        state.statusLogin = status;
    },
    setTokenLogin(state, token) {
        state.tokenLogin = token;
    },
   
  };
  
  const actions = {
    async login({ commit }, { e, p }) {
      try {
         
        const data = {  user: e,
                        password: p
        };
       
        const url = "/auth/Login";
  
        const resp = await axios.post(url, data, {
          headers: {
            'Content-Type': 'application/json',
            data
          },
        });
        let token="";
        const status=resp.data.status;
        switch (status) {
            case 200:  
              token=resp.data.payload.sessionToken;
 
              resp.data.payload.sessionToken='';
              localStorage.setItem('user',JSON.stringify(resp.data.payload));
         
              commit('setUser', resp.data.payload);
              commit('setTokenLogin',token);
              commit('setFaenas',resp.data.payload.info.faenas); 
              
               
              break;
         
          }
         
          commit('setStatusLogin',status);

      } catch (err) {
        console.log(err);
      }
    },
  };
  
  export default {
    state,
    getters,
    mutations,
    actions,
  };
  