<template>
  <div v-if="open" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all">
          <div class="absolute top-0 right-0 pt-4 pr-4">
            <button @click="close_modal" class="text-gray-400 hover:text-gray-500 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
          <div class="text-center">
            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 pl-10 pr-10 pb-2">
              {{ nombrejaula }}
            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 pl-10 pr-10 pb-2">
         <b> Setpoint {{ this.descripcionSetPoint }} {{ datos.tag }} {{ datos.unidad }} </b>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 pl-10 pr-10">
              <input type="text" v-model="valor" class="text-center text-xs w-full p-2 h-8 shadow-sm border-solid border-purple-800 border-2" />
            </div>

            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-2 pl-10 pr-10">
              {{  this.glosatipo }}
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            <button v-if="enviando !== true" @click="handleSetPoint" type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm">Setear Valor</button>
            <SpinnerLoading v-if="enviando"></SpinnerLoading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 import axios from "axios";
 import SpinnerLoading from "../SpinnerLoading";
export default {
  props: ['open','edititem','nombrejaula','datos','descripcion'],
  emits: ["updateOpen","close","onSend"],
  components: {
    SpinnerLoading
  
  },
  data(){
    return{
      enviando : false,
      descripcionSetPoint: '',
      valor: 0,
      openmodal: this.open,
      edititem_modal: this.edititem,
      glosatipo : ''
    }
  },

  /*props: {
    open: false,
    edititem: {label: '', value: '',id:''}
  },*/
  methods: {
    onSend(datos){  
       this.$emit('updateNuevoDato',  datos)
    },
    onCancel() {
      this.$emit('updateOpen', false)
    },
    close_modal() {
        console.log('cerrar modal 1');
        this.$emit("close")
    },
   handleSetPoint() {
    this.enviando=true;
      const dat = {    
            value: this.valor,
            parametro : this.datos.tipo
      };
      this.postSetObserved(dat)
    },
    async postSetObserved(dat) {
      let apiKey = this.$store.state.login.tokenLogin; 
      const response = await axios.post("/api/ctl/setSetpoint", dat, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${apiKey}`
          }
      });
      console.log(response);
      this.enviando=true;
      this.onSend(dat);
 
 
    },
  },
  mounted(){ 
 
    this.valor= this.datos.value;
    this.descripcionSetPoint=this.datos.descripcion;
    this.enviando=false;
  },
  watch:{
    datos: function(dat){ 
     this.valor= dat.value;
     this.descripcionSetPoint= dat.descripcion;
     this.glosatipo =   this.datos.tipo == 'maxMgl' ? 'max mg/l' : '%';
     this.enviando=false;
    },
    
  },
  computed: {
   
  
  },
}
</script>
