 
<script>
 
import router from "../../router";
 
 
export default {
  name: "LoginWebRoot",
  mounted(){
            window.localStorage.removeItem('user');
            this.data.email= this.email
            localStorage.setItem('user',JSON.stringify(this.data));
            this.$store.dispatch('user',this.data);
            console.log(this.data);
            router.push("/home");
  }, 
  data() {
    return {
      email: "superuser@gmail.com",
      password: "Oscar123@",
      showModal: false,
      data: 
          {
                idEmpresa: '1',
                nombreUsuario: 'Oxytek',
                rol:'Administrador',
                email:''
          }
      
    };
  },
  components: {
   
  },
  methods: {
   
    
  },
};
</script>
