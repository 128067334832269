<template>
  <div>
    <div>

      <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-6">

        <div class="col-span-2 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="text-sm   text-gray-500 truncate"> Faena </dt>
          <dd class="mt-1  ">{{ nombreFaena }} </dd>
        </div>

        <div class="col-span-3 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="text-sm  text-gray-500 truncate">Ubicación</dt>
          <dd class="mt-1   text-gray-900">{{ objDivisionAmpliar.nombreDivisionPadre }} / {{ objDivisionAmpliar.nombre
          }} </dd>
           <dt class="text-sm  text-gray-500 truncate">Rango tiempo</dt>
            <dd class="mt-1   text-gray-900">{{firstdate}} &raquo; {{lastdate}} </dd>
        </div>

        <div class="col-span-1 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="text-sm font-medium text-gray-500 truncate"></dt>
          <dd class="mt-1 text-2xl font-semibold text-gray-900">
            <button type="button" @click="exitGrafico"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Salir</button>

          </dd>
        </div>
      </dl>
    </div>
 
    <dl class="mt-5 grid grid-cols-12 gap-5 sm:grid-cols-12">
   
      <div class="col-span-12 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        {{ objDivisionAmpliar.nombre }} ORP (mV)
 
      </div>
    
    </dl>
   <SpinnerLoading v-show="series_ORP[0].data.length == 0"></SpinnerLoading>
    <VueApexCharts id="ORP" ref="ORP"     width="100%" :options="charOptionORP" :series="series_ORP"></VueApexCharts>


    <div class="col-span-12 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        {{ objDivisionAmpliar.nombre }} Concentración de Oxígeno (mg/l)
 
      </div>
    <SpinnerLoading v-show="series_O2conc[0].data.length == 0"></SpinnerLoading>
    <VueApexCharts id="o2conc" ref="o2conc"  width="100%" :options="charOptionsO2conc" :series="series_O2conc"></VueApexCharts>
  
    <div class="col-span-12 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        {{ objDivisionAmpliar.nombre }} Saturación de Oxígeno (%)
 
      </div>
    <SpinnerLoading v-show="series_O2conc[0].data.length == 0"></SpinnerLoading>
    <VueApexCharts id="o2sat" ref="o2sat"  width="100%" :options="charOptionsO2sat" :series="series_O2sat"></VueApexCharts>

    <div class="col-span-12 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        {{ objDivisionAmpliar.nombre }} pH
 
      </div>
    <SpinnerLoading v-show="series_pH[0].data.length == 0"></SpinnerLoading>
    <VueApexCharts id="pH" ref="pH"  width="100%" :options="charOptionspH" :series="series_pH"></VueApexCharts>


  </div>
</template>
<script>
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import SpinnerLoading from "../SpinnerLoading";
export default {
  props: ["idDivision", "objDivisionAmpliar", "titulo", "nombreFaena"],
  emits: ["chgGrafico", "exitGrafico"],
  components: {
    VueApexCharts, SpinnerLoading
  },
  data() {
    return {
      firstdate: '',
      lastdate: '',
      categorias: [],
      medicion: [],
      medicion2: [],
      categorias1: [],
      mediciones1: [],
      mediciones2: [],

      charOptionspH: {
        chart: {
          type: "area",
          id: "vuechart-jaula-medicion",
          group: 'jaula',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            },
            autoSelected: 'zoom'
          },
        },
  
         
        xaxis: {
          categories: [],
        },
        yaxis:{
             min:7,
             max:7.29
           
         },
        markers: {
          size: 4
        },
        colors: ['#FF1654'],
         dataLabels: {
          enabled: false,
        },

      },


      charOptionORP: {
        setPoint:280,
        chart: {
          type: "line",
          id: "vuechart-jaula-medicion-2",
          group: 'jaula',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            },
            autoSelected: 'zoom'
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis:{
         
          min:200,
          max:300
        },
        colors: ['#247BA0','#db2f00'],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 4
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        },
       

      },
      charOptionsO2conc: {
        setPoint:25.10,
        chart: {
          type: "line",
          id: "vuechart-jaula-medicion-2",
          group: 'jaula',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            },
            autoSelected: 'zoom'
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis:{
         
          min:24,
          max:26
        },
        colors: ['#22d3ee','#db2f00'],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 4
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        },

      },
      charOptionsO2sat: {
        setPoint:223,
        chart: {
          type: "line",
          id: "vuechart-jaula-medicion-2",
          group: 'jaula',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            },
            autoSelected: 'zoom'
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis:{
         
          min:210,
          max:230
        },
        colors: ['#701a75','#db2f00'],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 4
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        },

      },
      series_pH: [{
        name: 'pH',
        data: [],
         
                    
      }],
      series_ORP: [{
        name: 'ORP (mV)',
        data: []
      },{
        name: 'SetPoint',
        data:[]
      }],
      series_O2conc: [{
        name: 'Concentración de Oxígeno (mg/l)',
        data: []
      },{
        name: 'SetPoint',
        data:[]
      }]
      ,
      series_O2sat: [{
        name: 'Saturación de Oxígeno (%)',
        data: []
      },{
        name: 'SetPoint',
        data:[]
      }]
      
    }
  },

  methods: {
    polldata() {

      this.polling = setInterval(() => {

        this.loadDataTipoMedicion();

      }, 4000)

    },
    exitGrafico() {
      this.$emit("exitGrafico");
    },


    async loadDataTipoMedicion() {

      //"pH", 2, 7.12, "#FF1654"


      if (typeof (this.objDivisionAmpliar.id) === 'undefined') {
       // console.log('jaula nula ')
        return false;
      }
      let idjaula = this.objDivisionAmpliar.id;
     

      // ORP
      try {
        const response = await axios.get(
          "http://localhost:8080/api/Mediciones/GetMedicionesByIdJaulaByIdTipoMedicion?idJaula=" +
          idjaula + "&idTipoMedicion=4"
        );
        if (response.data) {
         console.log('obteniendo datos medicion ORP jaula:', idjaula)
          let updatedData = [];
          updatedData = response.data = [...response.data].splice(0, 12);
        //  updatedData = response.data
          let miscategorias2 = [];
          let missetpoints2=[];
          updatedData.forEach((mobile) => {
            miscategorias2.push(mobile["fechaMedicion"]);
          });

          let mismediciones2 = [];
          updatedData.forEach((mobile) => {
            let medicion = mobile["medicion"].toFixed(2);
            mismediciones2.push(medicion);
            missetpoints2.push( this.charOptionORP.setPoint);
          });
 

          this.series_ORP[0].data = mismediciones2
          this.series_ORP[1].data = missetpoints2
    
          //------------------------------------------------        
          // let minimo=  Math.min(...this.series_ORP[0].data)   - 20 
          //  this.$refs.ORP.options.yaxis.min =  minimo.toFixed(2) 
         
          
          /*   this.chartOptions2 = {
                    yaxis: {
                      min: 200
                    }
           };
           this.$refs.ORP.updateOptions(this.chartOptions2, false, true);
           */
        }
      } catch (err) {
        console.log(err);
      }

      // pH - Oxigeno
       try {
        const response = await axios.get(
          "http://localhost:8080/api/Mediciones/GetMedicionesByIdJaulaByIdTipoMedicion?idJaula=" +
          idjaula + "&idTipoMedicion=2"
        );
        if (response.data) {

           console.log('obteniendo datos medicion pH, jaula:',  idjaula)

          let updatedData = [];
          updatedData = response.data = [...response.data].splice(0, 12);
         // updatedData = response.data
          let miscategorias = [];
          updatedData.forEach((mobile) => {
            let fmedicion = mobile["fechaMedicion"];
            if (this.firstdate.length == 0) {
              let paso = fmedicion.substring(0, 9);
              paso = paso.split("-")
              paso[2]= parseInt(paso[2]) < 10 && `0${paso[2]}`;
              let aa = paso[2] + "-" + paso[1] + "-" + paso[0]
              this.firstdate = aa + " " + fmedicion.substring(11);
            }
            let paso = fmedicion.substring(0, 9);
          
            paso = paso.split("-");
            paso[2]= parseInt(paso[2]) < 10 && `0${paso[2]}`;
            console.log(paso[2])
            let aa = paso[2] + "-" + paso[1] + "-" + paso[0]
            this.lastdate = aa + " " + fmedicion.substring(11);


            let minuto_segundo = fmedicion.substring(fmedicion.length - 5);
            miscategorias.push(minuto_segundo);
          });



          let mismediciones = [];
          updatedData.forEach((mobile) => {
            let medicion = mobile["medicion"].toFixed(2);
            mismediciones.push(medicion);
          });

        
          this.charOptionspH = {
            xaxis: { categories: miscategorias } ,
     
          }
         
          /*  this.$refs.pH.updateOptions({
                  yaxis: {
                   min: 7
                  }
          })*/
         //  console.log(this.$refs.pH.options.yaxis.min,"<---")

          this.series_pH[0].data = mismediciones

           
        }
      } catch (err) {
        console.log(err);
      }


      // oxigeno concentracion
      try {
        const response = await axios.get(
          "http://localhost:8080/api/Mediciones/GetMedicionesByIdJaulaByIdTipoMedicion?idJaula=" +
          idjaula + "&idTipoMedicion=5"
        );
        if (response.data) {

           console.log('obteniendo datos medicion oxigeno concentracion, jaula:',  idjaula)

          let updatedData = [];
          updatedData = response.data = [...response.data].splice(0, 12);
         // updatedData = response.data
          let miscategorias5 = [];
          updatedData.forEach((mobile) => {
            let fmedicion = mobile["fechaMedicion"];
            if (this.firstdate.length == 0) {
              let paso = fmedicion.substring(0, 9);
              paso = paso.split("-")
              let aa = paso[2] + "-" + paso[1] + "-" + paso[0]
              this.firstdate = aa + " " + fmedicion.substring(11);
             
            }
            let paso = fmedicion.substring(0, 9);
            paso = paso.split("-")
            paso[2]= parseInt(paso[2]) < 10 && `0${paso[2]}`;
            let aa = paso[2] + "-" + paso[1] + "-" + paso[0]
            this.lastdate = aa + " " + fmedicion.substring(11);

           
            let minuto_segundo = fmedicion.substring(fmedicion.length - 5);
            
            miscategorias5.push(minuto_segundo);
          });



          let mismediciones = [];
          let missetpoint= [];
          updatedData.forEach((mobile) => {
            let medicion = mobile["medicion"].toFixed(2);
            console.log(medicion)
            mismediciones.push(medicion);
            missetpoint.push(this.charOptionsO2conc.setPoint)
             
          });

        
        
         
         

          this.series_O2conc[0].data = mismediciones
          this.series_O2conc[1].data = missetpoint

          
           
        }
      } catch (err) {
        console.log(err);
      }

      // oxigeno saturacion
      try {
        const response = await axios.get(
          "http://localhost:8080/api/Mediciones/GetMedicionesByIdJaulaByIdTipoMedicion?idJaula=" +
          idjaula + "&idTipoMedicion=6"
        );
        if (response.data) {

           console.log('obteniendo datos medicion oxigeno saturacion, jaula:',  idjaula)

          let updatedData = [];
          updatedData = response.data = [...response.data].splice(0, 12);
         // updatedData = response.data
          let miscategorias6 = [];
          updatedData.forEach((mobile) => {
            let fmedicion = mobile["fechaMedicion"];
            if (this.firstdate.length == 0) {
              let paso = fmedicion.substring(0, 9);
              paso = paso.split("-")
              let aa = paso[2] + "-" + paso[1] + "-" + paso[0]
              this.firstdate = aa + " " + fmedicion.substring(11);
             
            }
            let paso = fmedicion.substring(0, 9);
            paso = paso.split("-")
            paso[2]= parseInt(paso[2]) < 10 && `0${paso[2]}`;
            let aa = paso[2] + "-" + paso[1] + "-" + paso[0]
            this.lastdate = aa + " " + fmedicion.substring(11);

           
            let minuto_segundo = fmedicion.substring(fmedicion.length - 5);
            
            miscategorias6.push(minuto_segundo);
          });



          let mismediciones = [];
          let missetpoint=[];
          updatedData.forEach((mobile) => {
            let medicion = mobile["medicion"].toFixed(2);
            console.log(medicion)
            mismediciones.push(medicion);
            missetpoint.push(this.charOptionsO2sat.setPoint)
          });

        
          
         

          this.series_O2sat[0].data = mismediciones
          this.series_O2sat[1].data = missetpoint

           
        }
      } catch (err) {
        console.log(err);
      }

      // "ORP", 4, 277, "#247BA0"
      /*
      try {
        const response = await axios.get(
          "http://localhost:8080/api/Mediciones/GetMedicionesByIdJaulaByIdTipoMedicion?idJaula=" +
          idjaula + "&idTipoMedicion=4"
        );
        if (response.data) {
          let updatedData = [];
       
          updatedData = response.data
          let miscategorias = [];
          updatedData.forEach((mobile) => {
            miscategorias.push(mobile["fechaMedicion"]);
          });

          let mismediciones = [];
          updatedData.forEach((mobile) => {
            let medicion = mobile["medicion"];
            mismediciones.push(medicion);
          });


          this.charOptionspH = {
            xaxis: { categories: miscategorias }
          }
          this.series_ORP.data = mismediciones

        }
      } catch (err) {
        console.log(err);
      }
      */
    },



  },
  created() { },
  mounted() {
     
    this.loadDataTipoMedicion();
    this.polldata()


  },
  beforeUnmount() {
    clearInterval(this.polling)

  }
};
</script>