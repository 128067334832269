<template>
  <div>
    <ModalHelp :showModal="showModal" :texto="nombreIdTipoMedicion" @close="closeModalHelp">
    </ModalHelp>

    <div class="md:flex md:items-center md:justify-between bg-white">
      <div class="min-w-0 flex-1 text-left pl-8">
        <h1 class="mt-3 text-1xl font-extrabold tracking-tight text-cyan-800">
          {{ nombreMedicion }} {{ nombreIdTipoMedicion }}
        </h1>
      </div>
      <div class="mt-4 flex md:mt-0 md:ml-4">
        <span class="isolate inline-flex rounded-md shadow-sm p-3">
          <button type="button" @click="chgTipo(1)" :class="{
      'border-gray-500 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500':
        natural,
      '': !natural,
      'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10': true,
    }">
            Natural
          </button>

          <button type="button" @click="chgTipo(2)" :class="{
      'border-blue-600 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600':
        oxigenado,
      '': !oxigenado,
      'relative -ml-px inline-flex items-center rounded-r-md border bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 ': true,
    }">
            Oxigenado
          </button>
        </span>
        <span class="isolate inline-flex rounded-md shadow-sm ml-12 p-3">
          <button type="button" @click="loadMapa(f)"
            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
            Mapa
          </button>
          <button type="button" @click="showModalHelp()"
            class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
            ?
          </button>
        </span>
      </div>
    </div>
    <div class="bg-white shadow-lg">
      
      <div v-if="chartOptions.series.length == 0">
        <SpinnerLoading v-show="result_mediciones !== true"></SpinnerLoading>
      </div>
      <highcharts v-if="chartOptions.series" :constructor-type="'stockChart'" :options="chartOptions"></highcharts>
    </div>
  </div>
</template>
<script>
//import axios from "axios";

import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";

//import stockInit from 'highcharts/es-modules/Stock'
import stockInit from "highcharts/modules/stock";
import ModalHelp from "./modal/ModalHelp";
import SpinnerLoading from "../SpinnerLoading";

stockInit(Highcharts);
Highcharts.setOptions({
  chart: {},
  lang: {
    months: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    weekdays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
  },
});

export default {
  components: {
    highcharts: Chart,
    ModalHelp,
    SpinnerLoading
  },
  emits: ["loadMapa"],
  props: [
     "seriesMediciones",
    "seriesMediciones1",
    "seriesMediciones2",
    "idMedicion",
    "nombreMedicion",
    "tituloY",
  ],

  data() {
    return {
      natural: false,
      oxigenado: false,
      idTipo: 2,
      nombreIdTipoMedicion: "",
      mediciones: [],
      showModal: false,
      Centro2: [],
      chartOptions: {
        chart: {
          type: "line",
        },
        legend: {
          enabled: true,

          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },

        plotOptions: {
          line: {
            dataLabels: {
              enabled: false,
            },
            marker: {
              enabled: false,
            },

            enableMouseTracking: true,
            series: {
              showInNavigator: true,
            },
            
          },
          area: {
            fillOpacity: 0.5
             },

          series: {
            showInNavigator: true,
            zoneAxis: "x",
            zones: [
              {

              },
              {

              },
            ],
          },
        },

        xAxis: {
          showLastLabel: true,
        },
        yAxis: {
          opposite: false,
          title: {
            text: this.tituloY,
          },
          formatter: function (val) {
            return val.toFixed(2);
          },
          min: 0,
          max: 25,
        },
        series: [],

        navigator: {
          series: {
            lineWidth: 2,
            fillOpacity: 0.04,
            dataGrouping: {
              smoothed: false,
            },
          },
        },
        rangeSelector: {
          selected: 2,
          floating: true,
          y: 400,
        },
        title: {
          text: "",
        },


      },
    };
  },
  watch: {

    seriesMediciones: function (arrayMediciones) {
     
        let nuevoValor= arrayMediciones[this.idTipo]
        nuevoValor.forEach(obj => {
          // Inicializar el array zones
          obj.data = obj.data.map(([timestamp, cantidad]) =>
            cantidad === 0 ? [timestamp, null] : [timestamp, cantidad]
          );
        });
        
        this.chartOptions.series = nuevoValor;

 
      if(this.idTipo==2){

         this.natural = false;
        this.oxigenado = true;
      }else{
        this.natural = true;
        this.oxigenado = false;
      }

    },
   
    oxigenado: function (nuevoValor) {
      if (nuevoValor) {
        this.nombreIdTipoMedicion = "Oxigenado";
      } else {
        this.nombreIdTipoMedicion = "Natural";
      }
    },
  },

  methods: {
    chgTipo(tipo) {
      let nuevoValor= this.seriesMediciones[tipo]
        nuevoValor.forEach(obj => {
          // Inicializar el array zones
          obj.data = obj.data.map(([timestamp, cantidad]) =>
            cantidad === 0 ? [timestamp, null] : [timestamp, cantidad]
          );
        });
     
        
        this.chartOptions.series = nuevoValor;

       if (tipo == 1) {
        this.natural = true;
        this.oxigenado = false;
      } else {
        this.natural = false;
        this.oxigenado = true;
      }

      this.idTipo = tipo;
      this.nombreIdTipoMedicion = this.idTipo == "1" ? "Natural" : "Oxigenado";
    },
    showModalHelp() {
      console.log("show modal");
      this.showModal = true;
    },
    closeModalHelp() {
      console.log("show modal");
      this.showModal = false;
    },
    loadMapa(f) {
      console.log("load mapa ");
      this.$emit("loadMapa", f);
    },
  },

  mounted() {
    this.idTipo = 2;

    console.log("montando");
    this.natural = false;
    this.oxigenado = true;

    //  this.manipulaSeriesNatural()
  },
};
</script>
