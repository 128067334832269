<template>
  <header class=" h-100 flex flex-col lg:flex-row bg-white">
    <div class="w-full lg:w-3/8   h-100 pt-4 pl-8 ">
      <img src="../assets/logo_oxytek_2.png"  >
    </div>

    <!-- Segunda columna -->
    <div class="w-full lg:w-3/8 p-8 text-align-left">
         
    </div>
    <div class="w-full lg:w-38  p-8">

    </div>
  </header>

 



  <div class="min-h-screen flex flex-col lg:flex-row ml-12 mr-12  bg-white">

  
    <div class="w-full lg:w-25 bg-white-300 p-8">
      <div class="w-full lg:w-20  bg-white-300 "> <h3 class="text-2xl font-bold text-center text-gray-800 mb-4">Generador</h3></div>
      <div class="w-full flex "> <img src="../assets/monitoreoplanta/gen.jpg" alt="Generador" /></div>
      <div class="w-full">
        
        <div class="container-generador flex flex-wrap w-full mt-4  bg-white  shadow-lg rounded-lg p-3">
                
                <!-- Segunda columna: indicador -->
                <div class="col1 w-full lg:w-1/3 h-100 flex justify-center items-center">
                  <span :class="colorClassGen" class="ml-0 w-6 h-6  rounded-full"></span>
                  </div>
                <!-- Tercera columna: datos -->
                <div class="col2 w-full lg:w-1/3 flex flex-wrap lg:flex-row">
                <div class="flex w-full lg:w-10/10">
                  <div class="valor w-full lg:w-auto  text-sm">231</div>
                  <div class="caption w-full lg:w-3/10  text-sm">{{ generadorUme[0] }}</div>
               
                </div>
                <div class="flex w-full lg:w-10/10">
                  <div class="valor w-full lg:w-auto  text-sm">125</div>
                  <div class="caption w-full lg:w-3/10  text-sm">{{ generadorUme[1] }}</div>
         
                </div>
              </div>
            </div>
      </div>

    </div>
    <!-- Segunda columna -->
    <div class="w-full lg:w-25 bg-white-300 p-8">
      <div class="w-full lg:w-25 bg-white-300  flex justify-center items-center"> <h3 class="text-2xl font-bold text-center text-gray-800 mb-4">Airplus</h3></div>
      <div class="w-full flex"> <img src="../assets/monitoreoplanta/airplus.jpg" alt="Generador" /></div>
      <div class="w-full" v-for="setup in arrAirPlus" :key="setup.id">
        <ComponentePlanta :setup="setup" :datos="datosAirPlus[setup.id]" :estado="estadoAirPlus[setup.id]"></ComponentePlanta>
      </div>
    </div>
    <div class="w-full lg:w-25 bg-white-500 p-8">
      <div class="w-full lg:w-25 bg-white-500  "><h3 class="text-2xl font-bold text-center text-gray-800 mb-4">Oxyplus</h3></div>
      <div class="w-full flex "> <img src="../assets/monitoreoplanta/oxyplus.jpg" alt="Generador" /></div>
      <div class="w-full" v-for="setup in arrOxyPlus" :key="setup.id">
        <ComponentePlanta :setup="setup" :datos="datosOxyPlus[setup.id]" :estado="estadoOxyPlus[setup.id]"></ComponentePlanta>
      </div>
    </div>
    <div class="w-full lg:w-25 bg-white-500 p-8">

     <div class="w-full lg:w-25 bg-white-500  "><h3 class="text-2xl font-bold text-center text-gray-800 mb-4">Pedestal</h3></div>
      <div class="w-full flex"> <img src="../assets/monitoreoplanta/pedestal.jpg" alt="Generador" /></div>
      <div class="w-full" v-for="setup in arrPedestal" :key="setup.id">
        <ComponentePlanta :setup="setup"  :datos="datosPedestal[setup.id]" :estado="estadoPedestal[setup.id]"></ComponentePlanta>
      </div>
    </div>
  </div>


</template>


<script>
import axios from "axios";
import ComponentePlanta from "./monitoreoplanta/Componente"
export default {
  data() {
    return {
      message: 'Hello, Vue!',
      status: 0,
      generadorUme: [],
      arrAirPlus: [],
      arrOxyPlus: [],
      arrPedestal: [],
      airPlus: { 'id': '', 'medicion': [], 'valor': [], 'estado': null, 'img': 'airplus.jpg' },
      oxyPlus: { 'id': '', 'medicion': [], 'valor': [], 'estado': null, 'img': 'oxyplus.jpg' },
      pedestal: { 'id': '', 'medicion': [], 'valor': [], 'estado': null, 'img': 'pedestal.jpg' },
      datosOxyPlus:[],
      datosAirPlus:[],
      datosPedestal:[],
      estadoOxyPlus:[],
      estadoAirPlus:[],
      estadoPedestal:[]
    };
  },
  components: {
    ComponentePlanta
  },
  computed: {
    colorClassGen() {

      return this.status == "1" ? 'bg-green-500' : 'bg-gray-500'
    },
    //...mapState('auth',['userLogin']),
  },
  methods: {
    async getDataSetup() {
      try {
      const result = await axios.get("http://localhost:8080/json/monConfigPlant.json");
      if (result.data) {

        this.generadorUme = Array.isArray(result.data.ge) && result.data.ge !== null ? result.data.ge : [];

        if (result.data.airPlus !== null && typeof result.data.airPlus === 'object') {
          if (this.isValidNumber(result.data.airPlus.cant)) {
            let cantidad = parseInt(result.data.airPlus.cant, 10);
            let par = Array.isArray(result.data.airPlus.par) && result.data.airPlus.par !== null ? result.data.airPlus.par : []
            this.addInstancesAirPlus(cantidad, par);
           
          }
        }
        if (result.data.oxyPlus !== null && typeof result.data.oxyPlus === 'object') {
          if (this.isValidNumber(result.data.oxyPlus.cant)) {
            let cantidad = parseInt(result.data.oxyPlus.cant, 10);
            let par = Array.isArray(result.data.oxyPlus.par) && result.data.oxyPlus.par !== null ? result.data.oxyPlus.par : [];
            this.addInstancesOxiPlus(cantidad, par);
          }
        }

        if (result.data.pedestal !== null && typeof result.data.pedestal === 'object') {
          if (this.isValidNumber(result.data.pedestal.cant)) {
            let cantidad = parseInt(result.data.pedestal.cant, 10);
            let par = Array.isArray(result.data.pedestal.par) && result.data.pedestal.par !== null ? result.data.pedestal.par : [];
            this.addInstancesPedestal(cantidad, par);
          }
        }

      }

      this.polldata();
     } catch (error) {
        console.error('Error al obtener los setup:', error);
      }
    },
    async getdata() {
       try {
          const result = await axios.get("http://localhost:8080/json/monPlant.json");
          if (result.data) 
          {
            if (Array.isArray(result.data.airPlus) && result.data.airPlus !== null ) {
              for (let i = 0; i < result.data.airPlus.length; i++) {
                  let obj = result.data.airPlus[i]
                  this.datosAirPlus.push(obj.par);
                  this.estadoAirPlus.push(obj.estado);
                 
              }
            }

            if (Array.isArray(result.data.oxyPlus) && result.data.oxyPlus !== null ) {
        
              for (let i = 0; i < result.data.oxyPlus.length; i++) {
                  let obj = result.data.oxyPlus[i]
                  this.datosOxyPlus.push(obj.par);
                  this.estadoOxyPlus.push(obj.estado);
              }
            }

            if (Array.isArray(result.data.pedestal) && result.data.pedestal !== null ) {
              for (let i = 0; i < result.data.pedestal.length; i++) {
                  let obj = result.data.pedestal[i]
                  this.datosPedestal.push(obj.par);
                  this.estadoPedestal.push(obj.estado);
              }
            }

            
          
          
          }
        } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    },
    modifyInstancesAirPlus(par){
        console.log(par)
        for (let i = 0; i < par.length; i++) {
              this.data[i]=par[i];
              console.log(this.data[i],"<--")
        }
    },
    polldata() {
      this.polling = setInterval(() => { this.getdata(); }, 8000);
    },
    isValidNumber(value) {
      return value !== null && value !== undefined && !isNaN(parseInt(value, 10));
    },
    addInstancesAirPlus(x, par) {
      for (let i = 0; i < x; i++) {
        const newInstance = { ...this.airPlus, id: i, medicion: par  };
        this.arrAirPlus.push(newInstance);
      }
    },
    addInstancesOxiPlus(x, par) {
      for (let i = 0; i < x; i++) {
        const newInstance = { ...this.oxyPlus, id: i, medicion: par  };
        this.arrOxyPlus.push(newInstance);
      }
    },
    addInstancesPedestal(x, par) {
      for (let i = 0; i < x; i++) {
        const newInstance = { ...this.pedestal, id: i, medicion: par  };
        this.arrPedestal.push(newInstance);
      }
    },
   
  },
  mounted() {
    this.status = "1"
    this.getDataSetup();
  }
};
</script>