<template>
      <table class="table-auto mt-1    ">
        <tbody class="  bg-white  "  >
            <tr>  
                <td class="border-b-2   whitespace-nowrap text-xs     bg-blue-300"  :data-tippy-content="help_standard" >
                    <span   class=" w-full border-black bg-blue-300 text-white-800 text-xs mr-2  px-2.5 py-0.5 rounded dark:text-white-300 ">STANDARD</span>

                </td>
                <td class="whitespace-nowrap px-1 py-1 text-xs text-gray-700">{{ this.modos_operacion[0] }} %</td>
            </tr>
            <tr> <td cospan="2"></td></tr> 
         <!--   <tr class="">
                <td class="border-b-2 whitespace-nowrap  text-xs     bg-green-400"  :data-tippy-content="help_eco">
                    <span   class="  w-full bg-green-400 text-black text-xs  mr-2 px-2.5 py-0.5 rounded  ">ECO</span>
                </td>
                <td class="whitespace-nowrap px-1 py-1 text-xs text-gray-700">{{ this.modos_operacion[1] }} %</td>
            </tr>-->
            <tr>
                <td class="border-b-2  whitespace-nowrap  text-xs    pl-0 bg-orange-400" :data-tippy-content="help_max">
                    <span   class="  w-full bg-orange-400 text-black text-xs  mr-2 px-2.5 py-0.5 rounded  ">MAX LOAD</span>
                </td>
                <td class="whitespace-nowrap px-1 py-1 text-xs text-gray-700">{{ this.modos_operacion[1] }} %</td>
            </tr>
            <tr> <td cospan="2"></td></tr> 
            <tr>
                <td class="border-b-2  whitespace-nowrap  text-xs    pl-0 bg-yellow-400" :data-tippy-content="help_aire">
                    <span   class=" w-full bg-yellow-400 text-black text-xs  mr-2 px-2.5 py-0.5 rounded ">AIRE</span>
                </td>
                <td class="whitespace-nowrap px-1 py-1 text-xs text-gray-700">{{ this.modos_operacion[2] }} %</td>
            </tr>
            <tr> <td cospan="2"></td></tr> 
            <tr>
                <td class="whitespace-nowrap  text-xs     pl-0 bg-red-500"  :data-tippy-content="help_stand">
                    <span   class=" w-full bg-red-500 text-black text-xs  mr-2 px-2.5 py-0.5 rounded">STANDBY</span>
                </td>
                <td class="whitespace-nowrap px-1 py-1 text-xs text-gray-700">{{ this.modos_operacion[3] }} %</td>
            </tr>
            <tr> <td cospan="2"></td></tr> 
        </tbody>

    </table>
    
</template>
<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
export default {
    props: [
        "mensual", "idFaena"
    ],
    data: function () {
        return {
            modos_operacion: Array,
            help_standard:'<b>STANDARD:</b></br>Este modo prioriza el control del setpoint preconfigurado de oxígeno en la jaula o piscina independiente de que una unidad Airplus + Oxyplus opere bajo el 100% de capacidad. Los generadores se encienden por el sensor de presión propio que tienen.',
            help_eco:'<b>ECO:</b></br>Este modo prioriza la eficiencia del sistema por cuanto asegura que cada unidad Airplus + Oxyplus opere al 100% de capacidad en desmedro de alcanzar el set point de oxigenación en forma precisa. El objetivo es no permitir que se encienda un generador si es que no se requiere el 100% de su capacidad.',
            help_max:'<b>MAX LOAD:</b></br> Este modo prioriza la capacidad del sistema por cuanto enciende todas las unidades Airplus + Oxyplus distribuyendo el oxígeno en forma pareja en cada jaula o piscina en función de la lectura de los sensores, pero sin considerar un setpoint de corte.',
            help_aire:'<b>AIRE:</b></br> Este modo entrega la cantidad equivalente a un (1) compresores de aire para uso en distintas aplicaciones como cortinas, surgencias u otras.',
            help_stand: '<b>STANDBY:</b></br> Cuando el IPC detecta que no hay ningún compresor o generador de oxígeno está operando en demanda, asigna la planta a un modo o estado Stand-by.',
        }
    },
    mounted() {
        console.log(this.mensual)
       
        switch (this.idFaena) {
            case 1: this.modos_operacion = ["60",   "24", "12", "4"]; break;
            case 2: this.modos_operacion = ["65",   "10", "15", "10"]; break;
            case 3: this.modos_operacion = ["60",   "20", "16", "4"]; break;
        }

        tippy('[data-tippy-content]', {
            allowHTML: true,
            arrow: true,
            theme: 'light-border',
        });
       
    }
}
</script>