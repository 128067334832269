<template>
 
  <router-view />
</template>
 <script>
 
 export default {
  
     created(){
  
      this.$store.dispatch('user',JSON.parse(localStorage.getItem('user')) );
     } ,
     mounted(){
      
     

     }
 
 }
 </script>
<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  nav {
    padding: 30px;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  nav a.router-link-exact-active {
    color: #42b983;
  }
  .month-picker-input{
    width:148px!important;
  
  }
  .month-picker__container {
    width:300px!important;
  }

</style>
