<template>
    <div class="bg-white shadow-lg">


        <ModalHistorialCentroMensual :show="showModal" :seriesFaenas="seriesFaenas" @close="closeModal">
        </ModalHistorialCentroMensual>


        <div class="md:flex md:items-center md:justify-between bg-white pt-3">
            <div class="min-w-0 flex-1  text-left pl-8">

                <h1 class="  text-1xl font-extrabold text-left text-cyan-800">
                    ORP
                </h1>
            </div>
            <div class="mt-4 flex md:mt-0 md:ml-4">

              
            </div>

        </div>
        <SpinnerLoading v-show="result_mediciones !== true"></SpinnerLoading>
      
        <VueApexCharts :options="chartOptions" :series="series" :width="chartWidth" :height="chartHeight">
        </VueApexCharts>


    </div>
</template>
<script>

import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import SpinnerLoading from "../SpinnerLoading";



export default {
    components: {
        VueApexCharts,     SpinnerLoading,
    },
    props: {
        fechaConsulta: {
            type: Array,
            required: true
        },
        diasMes: {
            type: Array,
            required: true
        },
        idFaena: {
            type: String,
            required: true
        },
        arrayDias: {
            type: Array,
            required: true
        },

    },

    mounted() {


        if (this.fechaConsulta !== undefined) {


            //let f_inicio = this.convertirFormatoFecha(this.fechaConsulta[0]);
            //let f_fin = this.convertirFormatoFecha(this.fechaConsulta[1]);
            //let x = this.generateDateArray(f_inicio, f_fin);
            //this.chartOptions.xaxis.categories = x

            this.getdata()

        }




    },
    data() {
        return {
            result_mediciones : false,
            Centro2: [],
            idTipoMedicion: 4,
            nomMes: ['', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
            visibilidadSerie: false,
            contadorVisibilidad: 0,
            arrayObjJaulas: this.objJaulas,
            mes: this.mesConsulta,
            series: [
                {
                    name: "Disuelto mg/l",
                    type: 'line',
                    data: []
                    //data: [1.1,  1.8,  2.3, 2.7, 3.1, 2.8, 3.0, 2.9, 2.8,   2.6,   2.9,   2.8, 3.1 ]
                }
            ],
            chartWidth: '100%',
            chartHeight: 350,
            chartOptions: {
                chart: {

                    type: 'line',

                    toolbar: {
                        show: false
                    }
                },
                colors: ['#9200e8', '#00adaa'],
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'smooth'
                },

                fill: {
                    type: 'solid',
                    opacity: 0.9,

                },
                title: {
                    text: '',
                    align: 'left'
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns

                    },
                },
                markers: {
                    size: 1
                },

                xaxis: {
                    categories: this.arrayDias,

                },

                yaxis: [
                    {

                        title: {
                            text: 'milivolts',
                            style: {
                                color: "#9200e8",
                            },
                        },
                        min: 30,
                        max: 55,

                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: "#9200e8",
                        },
                        labels: {
                            style: {
                                colors: "#9200e8",
                            },
                            formatter: function (val) {
                                return val.toFixed(2);
                            },

                        },
                    },
                ]
            }



        }
    },
    methods: {


        convertirFormatoFecha(fechaOriginal) {

            if (!fechaOriginal) return false;

            const partes = fechaOriginal.split(" ");
            if (partes.length === 3) {
                const dia = partes[0];
                const mes = partes[1];
                const año = partes[2];

                const nuevaFecha = new Date(`${año}-${mes}-${dia}`);

                if (!isNaN(nuevaFecha)) {
                    const fechaFormateada = nuevaFecha.toISOString().slice(0, 10);
                    return fechaFormateada;
                }
            }

            return null;
        },


        async getdata() {
            this.medicionDiaria = [];
            this.medicionAcumulada = [];


            let f_inicio = this.convertirFormatoFecha(this.fechaConsulta[0]);
            let f_fin = this.convertirFormatoFecha(this.fechaConsulta[1]);



            this.fInicial = f_inicio + ` 00:00:00`;
            this.fFinal = f_fin + ` 05:00:00`;
            let newObject1 = [];

            const apiKey = this.$store.state.login.tokenLogin;
            const data = {
                idFaena: this.idFaena,
                tsi: `${this.fInicial}`,
                tsf: `${this.fFinal}`,
                granularity: "day"
            };
            const fetchData = async () => {
                try {
                    const url = "/api/mon/getConsolidatedData";
                    const response = await axios.post(url, data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `${apiKey}`
                        }
                    });


                    const datos = response.data.payload.params;

                    let filtro = datos.filter(parametro => parametro.id === 4);
                    if (filtro.length > 0) {
                        filtro.forEach(parametro => {

                            parametro.values.forEach(valueObj => {
                                console.log("**", valueObj.value)

                                let n = valueObj.value;

                                // f = new Date(f);
                                newObject1.push(n.toFixed(2));

                                this.result_mediciones=true;
                            });
                        });


                    }else{
                        newObject1=  new Array(this.arrayDias.length).fill(0);
                        this.result_mediciones=true;
                    }









                } catch (error) {
                    console.error(error);
                }
            };

            fetchData().then(() => {

                this.series[0].data = newObject1;
                console.log('newObject1', newObject1)


            }).catch(error => {
                console.error("Error processing data:", error);
            });

        },


    },
    watch: {

        arrayDias: function (nuevovalor) {


            try {
                if (nuevovalor !== '') {

                    this.chartOptions = {
                        xaxis: { categories: nuevovalor },
                        chart: {

                            type: 'line',
                            toolbar: {
                                show: false
                            },

                        },
                        colors: ['#9200e8'],
                        yaxis: [
                            {

                                title: {
                                    text: 'milivolts',
                                    style: {
                                        color: "#9200e8",
                                    },
                                },
                                min: 30,
                                max: 55,


                            },
                        ]
                    }
                    this.getdata()
                }

            } catch (error) {
                alert(error)
            }
        }

    },
 


}
</script>