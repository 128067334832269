<template>
  <div>
    <EditItemModal :nombrejaula="objJaula.descripcion" :datos="datosSetPoint" :open="modalstate" @updateNuevoDato="updateNuevoDato" @close="closeModal"></EditItemModal>
    
    <div class="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-2 pl-10 pr-10 pt-0 pb-0">
      <div class="text-xs   text-blue-600 border-solid border-0 border-indigo-300 pl-2 pr-2 text-center  pt-0 pb-0">
      </div>
      <div class="text-xs   text-blue-600 border-solid border-0 border-indigo-300 pl-2 pr-2    text-center  pt-0 pb-0">
      </div>
      <div class="col-span-2 ext-xs border-solid border-1 border-indigo-300 p-2 text-center   text-black font-bold  pt-0 pb-0">
        Setpoints</div>
    </div>
    
    <div class="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-2 pl-10 pr-10">
      <div class="text-xs col-span-2 border-solid border-0 border-indigo-300 pl-2 pr-2  pt-4  text-left">
       
        <span v-if="result_mediciones_haydatos"><span class="green custom-shadow"></span></span>
       
        <span v-if="this.idEstado === 9"><span class="red custom-shadow"></span></span>
        <span v-if="this.idEstado === 1"><span class="green custom-shadow"></span></span>
        <span v-if="this.idEstado === 2"><span class="blue custom-shadow"></span></span>
        <span v-if="this.idEstado === 3"><span class="yellow custom-shadow"></span></span>
        <span v-if="this.idEstado === 4"><span class="orange custom-shadow"></span></span>
        <span v-if="result_mediciones_haydatos==false"> <!-- o this.idEstado === 5-->
          <div class=" ml-2 rounded-full mb-1   flex items-center justify-center h-5 w-5 bg-gray-400">&nbsp;</div>
        </span>
        <span class="text-lg  font-bold ml-2 ">   {{ objJaula.descripcion }} </span>

      </div>

      <div  v-if="Object.keys(reqO2).length > 0"  class="text-xs   text-blue-600 border-solid border-0 border-indigo-300 pl-2 pr-2  pt-4 text-center">
        {{ this.reqO2.descripcion }}  {{ reqO2_ume }}  
      </div>
      <div  v-if="Object.keys(maxMgl).length > 0"  class="text-xs   text-blue-600 border-solid border-0 border-indigo-300 pl-2 pr-2  pt-4 text-center">
        {{ this.maxMgl.descripcion }}   {{ maxMgl_ume }}  
      </div>
      <div v-if="Object.keys(maxORP).length > 0"  class="text-xs text-purple-800 border-solid border-0 border-indigo-300 pl-2 pr-2  pt-4 text-center">

        {{ this.maxORP.descripcion   }}   {{ maxORP_ume   }}   
      </div>
    </div>
    <div class="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5  ml-6 mr-6 pb-0 ">
      <div class="text-xs  border-solid border-0 border-white p-2 text-center pt-7">
       
      </div>
      <div class="text-xs border-solid border-0 border-indigo-300 p-2 text-center pt-7">
        Temp &deg;C
      </div>
      <div   v-if="Object.keys(reqO2).length > 0"  class="text-xs border-l-2 border-t-2 border-b-2 border-r-0   bg-blue-600 border-indigo-300 p-2 text-center">
        <!-- SETPOINT SATURADO // requerido -->
       
        <input  @click="openmodal('reqO2',reqO2_esDefault)" type="text" v-model="reqO2.value"
         :class="[ 'text-center w-full text-xs p-2 h-8 shadow-sm border-solid border-2 border-blue-600',
        reqO2_esDefault ? 'bg-orange-100' : 'bg-white']"/>
      </div>
      <div v-if="Object.keys(maxMgl).length > 0"  class="text-xs border-l-2 border-t-2 border-b-2 border-r-0   bg-blue-600 border-indigo-300 p-2 text-center">
          <!-- SETPOINT OXIGENO DISUELTO // requerido -->
           
        <input @click="openmodal('maxMgl',maxMgl_esDefault)" type="text" v-model="maxMgl.value"
          :class="[ 'text-center w-full text-xs p-2 h-8 shadow-sm border-solid border-2 border-blue-600 ',
          maxMgl_esDefault ? 'bg-orange-100' : 'bg-white']"/>
      </div>
      <div v-if="Object.keys(maxORP).length > 0"  class="text-xs border-solid border-2 border-indigo-300 p-2 text-center  bg-purple-800">
          <!-- SET POINT ORP-->
         <!--  @click="openmodal('maxORP',maxORP_esDefault)"  -->
        <input type="text" v-model="maxORP.value"
        
          :class="[ 'text-center text-xs w-full p-2 h-8 shadow-sm border-solid border-purple-800 border-2 ',
          maxORP_esDefault ? ' bg-gray-200' : 'bg-white']"/>
      </div>
    </div>
    <div class="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5  ml-6 mr-6 pb-5 ">
      <div class="text-xs border-l-2 border-t-2 border-b-2 border-r-0  border-white p-2 text-center">
      
      </div>
      <div  class="text-xs   border-l-2 border-t-2 border-b-2 border-r-0  border-solid  border-indigo-300 p-2 text-center">
        {{ this.ulttemp  }}
      </div>
      
      <div v-if="Object.keys(reqO2).length > 0"
        class="text-xs border-l-2 border-t-2 border-b-2 border-r-2 border-indigo-300 p-2 text-center">
        {{ this.ultmaxmgl }} 
      </div>
      <div v-if="Object.keys(maxMgl).length > 0"
        class="text-xs border-l-2 border-t-2 border-b-2 border-r-2 border-indigo-300 p-2 text-center">
        {{ this.ultmgl }}
      </div>
      <div v-if="Object.keys(maxORP).length > 0"
        class="text-xs border-l-2 border-t-2 border-b-2 border-r-2 border-indigo-300 p-2 text-center bg-gray-200">
        {{ this.ultorp }}
      </div>
    </div>
    <SpinnerLoading v-show="result_mediciones !== true"></SpinnerLoading>
    <div class="chart-container">
      <VueApexCharts @click="propagajaula" @mouseover="propagajaula" ref="demoChart" :options="chartOptions"
        :series="series"></VueApexCharts>

    </div>
    <div class="chart-container">
      <div id="divfec" class="w-full flex justify-between">
        <div class="f1 pl-11">
          <!--    {{ firstdate }}<br>{{ firsthour }}-->
          {{ fechaInicialParche }}
          <br>
          {{ horaInicialParche }}
        </div>
        <div class="flex-grow text-center">
          <!--  {{ middledate }}<br>{{ middlehour }} -->
          {{ fechaMitadParche }}
          <br>
          {{ horaMitadParche }}
        </div>
        <div class="f2 text-left ml-1 pr-12">
          <!--     {{ lastdate }}<br>{{ lasthour }}-->

          {{ fechaFinalParche }}
          <br>
          {{ horaFinalParche }}

        </div>
      </div>
    </div>


  </div>

</template>
<script>

import VueApexCharts from "vue3-apexcharts";
import SpinnerLoading from "../SpinnerLoading";
import EditItemModal from './EditItemModal.vue'
import axios from "axios";
export default {
  props: [  "objJaula"  ],
  emits: ["ampliaGrafico", "muestraFoto", "fecha_hasta"],

  components: {
    VueApexCharts,
    SpinnerLoading,
    EditItemModal
  },
  data: function () {
    return {
      
      modalstate: false,
      idEstado: 5,
      setpoint1: 6.2,
      setpoint2: 300,
      idJaula: 0,
      ultfecha: '',
      ultmgl: 0,
      ultmaxmgl: 0,
      ultorp: 0,
      ulttemp:0,
      temperatura: 0,
      saturacion: 0,
      idSensorAFiltrar0:0,
      idSensorAFiltrar1:0,
      temp: [
        "0",
        "12.1",
        "12.8",
        "12.3",
        "12.6",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
      ],
      sat: [
        "0",
        "84.3",
        "86.5",
        "88.7",
        "90.9",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
      ],
      id1: 0,
      id2: 0,
      sensores: [],
      sensor: [{ 'id': '', 'nombre': '' }, { 'id': '', 'nombre': '' }],

     

      reqO2: {},
      maxORP: {},
      maxMgl: {},
      reqO2_tipo: '',
      maxORP_tipo: '',
      maxMgl_tipo: '',
      reqO2_ume: '',
      maxORP_ume: '',
      maxMgl_ume: '',
      reqO2_esDefault: '',
      maxORP_esDefault: '',
      maxMgl_esDefault: '',
      datosSetPoint: {},
      descripcionSetPoint: '',
      ume1: '',
      ume2: '',
      tipo1: '',
      tipo2: '',
      result_mediciones: false,
      result_mediciones_haydatos: false,
      firstdate: "",
      firsthour: "",
      lastdate: "",
      lasthour: "",
      middledate: "",
      middlehour: "",
      fechaInicialParche: "",
      horaInicialParche: "",
      fechaFinalParche: "",
      horaFinalParche: "",
      fechaMitadParche: "",
      horaMitadParche: "",
      chartOptions: {
        chart: {
          type: "line",
          id: "vuechart-example-" + this.idJaula,
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
          series: {
            showInNavigator: true,
          },
          padding: {
            left: 0,
            right: 0,
          },
          margin: 0,
        },
        dataLabels: {
          enabled: false,
        },
        colors: [  "#0037ff", "#00adaa"],
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
        },
        legend: {
          position: "top", 
        },
        markers: {
          size: 4,
        },
        yaxis: [
        {
          
            min: 0,
            max: 15,
            axisTicks: {
              show: true,
            },

            axisBorder: {
              show: true,
              color: "#0037ff",
            },
            labels: {
              style: {
                colors: "#0037ff",
              },
              formatter: function (val) {
                return val.toFixed(2);
              },
            },
            title: {
              text: 'miligramos / litro',
              style: {
                color: "#0037ff",
              },
            },
          
          },
        {
          opposite: true,
            min: 0,
            max: 15,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#00adaa",
            },
            labels: {
              style: {
                colors: "#00adaa",
              },
              formatter: function (val) {
                return val.toFixed(2);
              },
            },
            title: {
              text: '°C',
              style: {
                color: "#00adaa",
              },
            },
          }
       
        
        ],
      },

      series: [
        {
          name: '',
          data: [],
        },
        {
          name: '',
          data: [],
        },
      ],
      /*  series2: [
          {
            name: "Concentración de Oxígeno (mg/l)",
            data: [],
          },
          {
            name: "Saturación de Oxígeno (%)",
            data: [],
          }
        ],*/
    };
  },
  methods: {
    getRnd(min, max) {
      let flot = Math.random() * (max - min + 1) + min;

      return flot.toFixed(2)
    },
    getRndUno(min, max) {
      let flot = Math.random() * (max - min + 1) + min;

      return flot.toFixed(1)
    },
    propagajaula() {
      this.$emit("muestraFoto", this.idJaula, this.label);
    },
    polldata() {
      this.polling = setInterval(() => {
        this.getdataendpoint();
      }, 50000);
    },

    closeModal() {
      this.modalstate = false
    },
    updateNuevoDato(datos){
        console.log('updateNuevoDato',datos)
        if (datos.parametro == 'reqO2' ){
          this.reqO2.value = datos.value;
        }
        if (datos.parametro == 'maxMgl' ){
         
          this.maxMgl.value = datos.value;
        }
        this.modalstate = false
    },
    async getdataendpoint() {

 


    var objFechaHora = new Date();
  
     //const fechaHoraStr = "2024-07-11 00:00:00";      

     let objFecha = new Date();
 
      const y = objFecha.getFullYear();
      const m = String(objFecha.getMonth() + 1).padStart(2, '0'); // Los meses   son 0-11
      const d = String(objFecha.getDate()).padStart(2, '0');

      const h = String(objFecha.getHours()).padStart(2, '0');
      const min = String(objFecha.getMinutes()).padStart(2, '0');
      const s = String(objFecha.getSeconds()).padStart(2, '0');
    
      const fechaHoraStr = `${y}-${m}-${d} ${h}:${min}:${s}`;
 
      const [fecha, hora] = fechaHoraStr.split(' ');
      const [year, month, day] = fecha.split('-').map(Number);
      const [hours, minutes, seconds] = hora.split(':').map(Number);
      objFechaHora = new Date(year, month - 1, day, hours, minutes, seconds);
   
 
      console.log('objFechaHora',objFechaHora);
      var nuevoFormato = {
        year: objFechaHora.getFullYear(),
        month: ('0' + (objFechaHora.getMonth() + 1)).slice(-2),
        day: ('0' + objFechaHora.getDate()).slice(-2),
        hours: ('0' + objFechaHora.getHours()).slice(-2),
        minutes: ('0' + objFechaHora.getMinutes()).slice(-2),
        seconds: ('0' + objFechaHora.getSeconds()).slice(-2)
      };

      this.fechaFinalParche = `${nuevoFormato.day}-${nuevoFormato.month}`;
      this.horaFinalParche = `${nuevoFormato.hours}:${nuevoFormato.minutes}`;
      const fFinal = `${nuevoFormato.year}-${nuevoFormato.month}-${nuevoFormato.day} ${nuevoFormato.hours}:${nuevoFormato.minutes}:${nuevoFormato.seconds}`;


      var objFechaHoraMedio = new Date();
      objFechaHoraMedio.setTime(objFechaHora.getTime() - 43200000);
      nuevoFormato = {
        year: objFechaHoraMedio.getFullYear(),
        month: ('0' + (objFechaHoraMedio.getMonth() + 1)).slice(-2),
        day: ('0' + objFechaHoraMedio.getDate()).slice(-2),
        hours: ('0' + objFechaHoraMedio.getHours()).slice(-2),
        minutes: ('0' + objFechaHoraMedio.getMinutes()).slice(-2),
        seconds: ('0' + objFechaHoraMedio.getSeconds()).slice(-2)
      };

      this.fechaMitadParche = `${nuevoFormato.day}-${nuevoFormato.month}`;
      this.horaMitadParche = `${nuevoFormato.hours}:${nuevoFormato.minutes}`;



      objFechaHora.setTime(objFechaHoraMedio - 43200000);
      nuevoFormato = {
        year: objFechaHora.getFullYear(),
        month: ('0' + (objFechaHora.getMonth() + 1)).slice(-2),
        day: ('0' + objFechaHora.getDate()).slice(-2),
        hours: ('0' + objFechaHora.getHours()).slice(-2),
        minutes: ('0' + objFechaHora.getMinutes()).slice(-2),
        seconds: ('0' + objFechaHora.getSeconds()).slice(-2)
      };

      this.fechaInicialParche = `${nuevoFormato.day}-${nuevoFormato.month}`;
      this.horaInicialParche = `${nuevoFormato.hours}:${nuevoFormato.minutes}`;
      const fInicial = `${nuevoFormato.year}-${nuevoFormato.month}-${nuevoFormato.day} ${nuevoFormato.hours}:${nuevoFormato.minutes}:${nuevoFormato.seconds}`;

       


      if (!this.idJaula) {
        return false;
      }
      if (!this.sensor) {
        return false;
      }

      const apiKey = this.$store.state.login.tokenLogin;

      const data = {
        idUnit: this.idJaula,
        tsi: `${fInicial}`,
        tsf: `${fFinal}`
      };


      const fetchData = async () => {
        try {
          const url = "/api/mon/getUnitData";
          const response = await axios.post(url, data, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${apiKey}`
            }
          });

        
          const datos = response.data.payload.sensors;
       

   
        
          let temperaturaValues = [];
          let oxigenoValues = [];

          // ¿ SIEMPRE ES UN SENSOR POR JAULA ?
          this.idSensorAFiltrar1 = this.objJaula.sensores[0]; // viene de props
          
          datos.filter(sensor => sensor.id === this.idSensorAFiltrar1)
            .forEach(sensor => {
              sensor.params.forEach(param => {
                let id = param.id;
                let  updatedData = [... param.values].splice(-24);
                updatedData.forEach(valor => {
                  // {'value':valor.value,'ts':valor.ts}
                 
                  switch(id){
                     case 1:    temperaturaValues.push({ ...valor }); break;
                     case 5:    oxigenoValues.push({ ...valor }); break;
                     case 4:    this.ultorp = valor.value.toFixed(2); break;
                     case 6:    this.ultmaxmgl = valor.value.toFixed(2);break;

                  }

                 /*  if (id == 1) {
                    temperaturaValues.push({ ...valor });
                  }
                  if (id == 5) {
                    oxigenoValues.push({ ...valor });
                  }
                  if (id== 4){
                    this.ultorp = valor.value.toFixed(2);
                  }
                  if (id== 6){
                    this.ultmaxmgl = valor.value.toFixed(2);
                  } */

                  this.result_mediciones_haydatos = true;
                });
              });
            });

    
          // Procesar oxigenoValues en una promesa
          return new Promise((resolve, reject) => {
            try {
           
              let mediciones_oxigeno = [];
              let mediciones_temp = [];
              let contador = 0;
              let categorias = [];

              temperaturaValues.forEach((mobile) => {
                
                let fmedicion = mobile["ts"];
                this.ultfecha = mobile["ts"];
                let [year, month, day, hour, minute] = fmedicion.split(/[-: ]/);
                let fec = `${day}-${month}`;
                let hora = `${hour}:${minute}`;
                
                if (contador === 0) {
                  this.firstdate = fec;
                  this.firsthour = hora;
                }
                if (contador === 12) {
                  this.middledate = fec;
                  this.middlehour = hora;
                }
                contador++;
                this.lastdate = fec;
                this.lasthour = hora;

                let minuto_segundo = fmedicion.substring(fmedicion.length - 2);
                categorias.push(minuto_segundo);
                let med = mobile["value"];
                mediciones_temp.push(med);
                this.ulttemp= med.toFixed(2);
                //console.log(med)
                console.log(year)
                // let medRandom = this.getRnd(300.1, 300.9);
                // medicionesRandom.push(medRandom);
                // this.ultorp = medRandom;
              });

              oxigenoValues.forEach((mobile) => {
                
                let fmedicion = mobile["ts"];
                this.ultfecha = mobile["ts"];
                let [year, month, day, hour, minute] = fmedicion.split(/[-: ]/);
                let fec = `${day}-${month}`;
                let hora = `${hour}:${minute}`;
                
                if (contador === 0) {
                  this.firstdate = fec;
                  this.firsthour = hora;
                }
                if (contador === 12) {
                  this.middledate = fec;
                  this.middlehour = hora;
                }
                contador++;
                this.lastdate = fec;
                this.lasthour = hora;

                let minuto_segundo = fmedicion.substring(fmedicion.length - 2);
                categorias.push(minuto_segundo);
                let med = mobile["value"];
                mediciones_oxigeno.push(med);
                this.ultmgl = med.toFixed(2);
                //console.log(med)
                console.log(year)
                // let medRandom = this.getRnd(300.1, 300.9);
                // medicionesRandom.push(medRandom);
                // this.ultorp = medRandom;
              });
              this.series[1].data = mediciones_temp;
              this.series[0].data = mediciones_oxigeno;
             
              resolve({
                temperaturaValues,
                oxigenoValues,
                
                categorias,
                ultfecha: this.ultfecha,
                firstdate: this.firstdate,
                firsthour: this.firsthour,
                middledate: this.middledate,
                middlehour: this.middlehour,
                lastdate: this.lastdate,
                lasthour: this.lasthour,
                ultmgl: this.ultmgl
              });
            } catch (error) {
              reject(error);
            }
          });
        } catch (error) {
          console.error(error);
        }
      };

      fetchData().then(() => {
   
        this.chartOptions = {
          xaxis: { categories: [] }, //categorias
        };

        this.result_mediciones = true;
       
      }).catch(error => {
        console.error("Error processing data:", error);
      });






    },
    ampliagrafico() {

      this.$emit("ampliaGrafico", this.idJaula, this.label);
    },
    openmodal(tag,esdefault ) {
      console.log(esdefault)
      this.datosSetPoint = {}
    
      if (tag == 'maxORP') {
        this.datosSetPoint.value       = this.maxORP.value;
        this.datosSetPoint.descripcion = this.maxORP.descripcion;
         
      }
      if (tag == 'reqO2') {
        this.datosSetPoint.value       = this.reqO2.value;
        this.datosSetPoint.descripcion =  this.reqO2.descripcion;
        this.datosSetPoint.tipo        = tag;
      }
      if (tag == 'maxMgl') {
        this.datosSetPoint.value       = this.maxMgl.value;
        this.datosSetPoint.descripcion = this.maxMgl.descripcion;
        this.datosSetPoint.tipo        = tag;
      }
      console.log('descripcionSetPoint', this.descripcionSetPoint)
      
      this.modalstate = true
    },
    getSetPoints() {
      
      let setpoints = this.objJaula.setpoints;
     
      if (Array.isArray(setpoints) && setpoints.length > 0) {
        this.filtraSetPoints(setpoints);
      }
       
       if (Object.keys(this.reqO2).length > 0){
          this.reqO2_tipo=this.parametroSetPointsLogin[this.reqO2.idPar].tipo;
          this.reqO2_ume=this.parametroSetPointsLogin[this.reqO2.idPar].unMed;
       }
      if (Object.keys(this.maxMgl).length > 0){
        this.maxMgl_tipo=this.parametroSetPointsLogin[this.maxMgl.idPar].tipo;
        this.maxMgl_ume=this.parametroSetPointsLogin[this.maxMgl.idPar].unMed;
      }
      if (Object.keys(this.maxORP).length > 0){
        this.maxORP_tipo=this.parametroSetPointsLogin[this.maxORP.idPar].tipo;
        this.maxORP_ume=this.parametroSetPointsLogin[this.maxORP.idPar].unMed;     
      }

    },
    getDefaultSetPoints() {
     
      let defaultsetpoints = this.$store.state.login.userLogin.info.default_setpoints;
      if (Array.isArray(defaultsetpoints) && defaultsetpoints.length > 0) {
        this.filtraDefaultSetPoints(defaultsetpoints);
      }
       
       if (Object.keys(this.reqO2).length > 0){
   
          this.reqO2_ume= this.reqO2.unMedida;
          this.reqO2_esDefault =  this.reqO2.esDefault;
          this.reqO2.value = this.reqO2.valor;
       }
      if (Object.keys(this.maxMgl).length > 0){
 
        this.maxMgl_ume= this.maxMgl.unMedida;
        this.maxMgl_esDefault =  this.maxMgl.esDefault;
        this.maxMgl.value = this.maxMgl.valor;
      }
      if (Object.keys(this.maxORP).length > 0){
 
        this.maxORP_ume= this.maxORP.unMedida;   
        this.maxORP_esDefault =  this.maxORP.esDefault; 
        this.maxORP.value = this.maxORP.valor; 
      }

    },
    /*
    "parametro_sensores": [
                {
                    "id": 2,
                    "descripcion": "Sensor RDO",
                    "parametros": [
                        {
                            "id": 1,
                            "tipo": "Temperatura",
                            "unidad": "°C"
                        },

    "default_setpoints": [
                {
                    "idPar": 5,
                    "tipo": "Centro de engorda en mar",
                    "tag": "max mg/l",
                    "descripcion": "Oxígeno Disuelto",
                    "unMedida": "mg/l",
                    "valor": 7
                },
    */
    getSensores() {
      console.log('getSensores')
     //let arrDefaultSetpoints = this.$store.state.login.userLogin.info.default_setpoints;
      
      let parametros_sensores = this.$store.state.login.userLogin.info.parametro_sensores;
     
      let sensores = this.objJaula.sensores
      let parametros_filtrados = this.filtrarParametros(sensores, parametros_sensores);
      this.ume1 = 'none'
      this.ume2 = 'none'
      if (Array.isArray(parametros_filtrados) && parametros_filtrados.length > 0) {

       
        this.id0 = parametros_filtrados[0]?.id ?? 'none';
        this.id1 = parametros_filtrados[1]?.id ?? 'none';

        this.tipo0 = parametros_filtrados[0]?.tipo ?? 'none';
        this.tipo1 = parametros_filtrados[1]?.tipo ?? 'none';

        this.ume0 = parametros_filtrados[0]?.unidad ?? 'none';
        this.ume1 = parametros_filtrados[1]?.unidad ?? 'none';


        if (this.id0 != '') {
          this.sensor[1].id = this.id0;  // oxigeno disuelto
         
          // leyenda   para el grafico
          this.series[1].name= this.tipo0 + ' ' + this.ume0;
        }
        if (this.id1 != '') {
          this.sensor[0].id = this.id1; 
          // para leyenda del grafico
          this.series[0].name= this.tipo1 + ' ' + this.ume1;
        }

      } 
    },
    filtraSetPoints(setpoints) {
      console.log('setpoints',setpoints)
      setpoints.forEach( s => {
        if (this.tagMappingSetPointsLogin[s.idPar]) {
          this[this.tagMappingSetPointsLogin[s.idPar]] = { 'id': s.id, 'idPar': s.idPar,  'value': s.value };
        }
      })
    },
    filtraDefaultSetPoints(defaultsetpoints) {
       
      
      let setpoints = this.objJaula.setpoints;
      defaultsetpoints.forEach( s => {
          let obj =  { 'id': s.id, 'idPar': s.idPar,  'descripcion': s.descripcion,'tipo': s.tipo,'unMedida': s.unMedida};
          // busca valor setpoint en objJaula.setpoints
         
          
          // si no lo encuentra, poner valor por defecto  s.valor
          let resp= this.filtraValorSetPoint(setpoints, s)
          switch(s.idPar){
              case 4: this.maxORP = obj ;
                      this.maxORP.valor = resp[0]
                      this.maxORP.esDefault= resp[1]
                      break;
              case 5: this.maxMgl = obj 
                      this.maxMgl.valor= resp[0]
                      this.maxMgl.esDefault= resp[1]
                      break;
              case 6: this.reqO2 = obj ;
                      this.reqO2.valor=resp[0]
                      this.reqO2.esDefault=resp[1]
                      break;
          }
       
      })
    },
    filtraValorSetPoint(setpoints,s){
      let filteredData = setpoints.filter(item => item.idPar === s.idPar);
      let valor=0;
      let esDefault;
      if (filteredData && Array.isArray(filteredData)){
        valor= filteredData[0] ? filteredData[0].value : s.valor;
        esDefault= filteredData[0] ? false : true;
        
      }
     
      
      return [valor,esDefault];
    },
    filtrarParametros(sensores, parametro_sensores) {
      let resultados = [];

      sensores.forEach(sensorId => {
        parametro_sensores.forEach(parametro => {
          if (sensorId === parametro.id) {
            resultados = resultados.concat(parametro.parametros);
          }
        });
      });

      return resultados;
    },
    watch:{
      
      nuevoReqO2: function(nuevovalor){
        this.reqO2.value = nuevovalor
      }
    }
  },
  created() { },
  mounted() {

    this.idJaula = this.objJaula.id,
    this.temperatura = this.getRndUno(10, 14)
    this.saturacion = this.getRndUno(40, 80)
    this.getdataendpoint();
    this.getSensores();
    // this.getSetPoints();
    this.getDefaultSetPoints();
    this.polldata();
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },

};
</script>
