<template>
  <div>
 
    <div
      v-if="showPanelAyuda"
      style="z-index: 2000"
      class="relative z-10"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <!-- Background backdrop, show/hide based on slide-over state. -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex pl-10 max-w-full"
          >
            <div class="pointer-events-auto w-screen max-w-4xl">
              <div
                class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
              >
                <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <div
                      class="inline-flex h-10 w-10 items-center justify-center rounded-md text-white sm:h-12 sm:w-12"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-8 h-8"
                        style="color: #fff"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>
                    </div>
                    <h2
                      class="text-1xl font-bold tracking-tight text-white sm:text-4xl"
                    >
                      Información de esta pantalla
                    </h2>

                    <div class="ml-3 flex h-7 items-center">
                      <button
                        @click="loadAyuda(false)"
                        type="button"
                        class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      >
                        <span class="sr-only">Close panel</span>
                        <svg
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="mt-1"></div>
                </div>
                <div class="relative flex-1 py-6 px-2 sm:px-2">
                  <!-- Replace with your content -->
                  <div class="absolute inset-0 py-6 pt-8 sm:px-2">
                    <img src="../../assets/historial.png" />
                  </div>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showPanelExportarExcel"
      style="z-index: 2000"
      class="relative z-10"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <!-- Background backdrop, show/hide based on slide-over state. -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex pl-10 max-w-full"
          >
            <!--
                Slide-over panel, show/hide based on slide-over state.

                Entering: "transform transition ease-in-out duration-500 sm:duration-700"
                  From: "translate-x-full"
                  To: "translate-x-0"
                Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
                  From: "translate-x-0"
                  To: "translate-x-full"
              -->
            <div class="pointer-events-auto w-screen max-w-4xl">
              <div
                class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
              >
                <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <div
                      class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                        />
                      </svg>
                    </div>
                    <h2
                      class="text-lg font-medium text-white"
                      id="slide-over-title"
                    >
                      Exportar datos a excel
                    </h2>

                    <div class="ml-3 flex h-7 items-center">
                      <button
                        @click="loadModalExportarExcel(false)"
                        type="button"
                        class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      >
                        <span class="sr-only">Close panel</span>
                        <svg
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="mt-1"></div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div class="absolute inset-0 py-6 pt-8 sm:px-6">
                    <div
                      class="h-full border-2 border-gray-200"
                      aria-hidden="true"
                    >
                      <h2
                        class="text-lg font-medium text-gray-900 pt-8"
                        id="slide-over-title"
                      >
                        Seleccione un rango de fechas
                      </h2>

                      <div
                        class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 pl-8"
                      >
                        <div class="bg-white align-right mb-4 mt-3">
                          <CalendarioFaenas
                            :fechasSeleccionadas="fechasSeleccionadas"
                          ></CalendarioFaenas>
                        </div>
                      </div>

                      <div
                        class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 pl-8"
                      >
                        <div class="bg-white mb-4 mt-3">
                          <fieldset>
                            <div class="mt-2 space-y-5 justify-center">
                              <div>
                                <div
                                  class="flex flex-shrink-0 px-4 py-4 text-center"
                                >
                                  <button
                                    type="button"
                                    @click="loadModalExportarExcel(false)"
                                    class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    Salir
                                  </button>
                                  <button
                                    type="submit"
                                    @click="exportarExcel()"
                                    class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    Exportar datos a excel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showPanelExportar"
      style="z-index: 2000"
      class="relative z-10"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <!-- Background backdrop, show/hide based on slide-over state. -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
          >
            <div class="pointer-events-auto w-screen max-w-4xl">
              <div
                class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
              >
                <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <div
                      class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12"
                    >
                      PDF
                    </div>
                    <h2
                      class="text-lg font-medium text-white"
                      id="slide-over-title"
                    >
                      Generar reporte en PDF
                    </h2>

                    <div class="ml-3 flex h-7 items-center">
                      <button
                        @click="loadExportarPdf(false)"
                        type="button"
                        class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      >
                        <span class="sr-only">Close panel</span>
                        <svg
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="mt-1"></div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div class="absolute inset-0 py-6 pt-1 sm:px-6 h-full">
                  

                    <div class="grid grid-cols-1 md:grid-cols-1 gap-4">

  <!-- Fila 0 -->
  <div class="bg-gray-200 p-4"></div>
                  <div class=" ">
                    Mes
                    
                  </div>
                  <div class="inline-flex justify-center ">

                    <month-picker-input
                                    :default-year="defaultYear1"
                                    :default-month="defaultMonth1"
                                    style="z-index: 2004"
                                    :lang="selectedLang"
                                    :min-date="minDate1"
                                    @change="showDate"
                                    :max-date="maxDate1"
                                  ></month-picker-input>
                  </div>


                      
                
 

          

                   

                  <!-- Fila 5 -->
                  <div class="bg-white-4"></div>
                  <div class="bg-gray-300 p-4"> 


                    <button
                                    type="button"
                                    @click="loadExportarPdf(false)"
                                    class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    Salir
                                  </button>
                                  <button
                                    type="button"
                                    @click="descargaPdf()"
                                    class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    Generar PDF
                                  </button>

                  </div>
                </div>


                  </div>
                  
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--- menu nuevo-->
    <div
      class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-1 pb-5 pt-2"
    >
      <div class="container mx-auto pt-1 pb-0 pl-0 pr-0">
        <div class="lg:flex">
          <div class="lg:w-2/3 pl-0">
            <div class="container">
              <div class="w-full">
                <table class="mt-1 border-spacing-0 w-full">
                  <tbody class="  ">
                    <tr>
                      <td class=""></td>
                      <td class=""></td>
                      <td class="">
                        <month-picker-input
                          @input="this.chgFec1"
                          @month-selected="obtenerNumeroMes"
                          :default-year="defaultYear1"
                          :default-month="defaultMonth1"
                          :lang="selectedLang"
                          :min-date="minDate1"
                          @change="showDate"
                          :max-date="maxDate1"
                        ></month-picker-input>
                      </td>
                      <td class=""></td>
                      <td class=""></td>
                      <td class=""></td>
                      <td class=""></td>
                      <td class=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="w-full mt-2">
                <table class="mt-5 border-spacing-0 m-0 w-full pr-3">
                  <tbody class="w-full border-spacing-0">
                    <tr>
                      <td
                        class="whitespace-nowrap py-1 text-sm text-gray-900 text-center"
                      ></td>

                      <td
                        class="whitespace-nowrap py-1 text-sm text-gray-900 text-right"
                      ></td>
                      <td
                        class="whitespace-nowrap py-1 text-sm text-gray-700 text-left"
                      ></td>

                      <td
                        class="whitespace-nowrap py-1 pr-0 text-sm text-gray-900 text-right"
                      ></td>
                      <td
                        class="whitespace-nowrap py-1 text-sm text-gray-700"
                      ></td>

                      <td
                        class="whitespace-nowrap py-1 pr-0 text-sm text-gray-900 text-right"
                      ></td>
                      <td
                        class="whitespace-nowrap py-1 text-sm text-gray-700"
                      ></td>

                      <td
                        class="whitespace-nowrap py-1 pr-0 text-sm text-gray-900 text-right"
                      ></td>
                      <td
                        class="whitespace-nowrap py-1 text-sm text-gray-700"
                      ></td>

                      <td
                        class="whitespace-nowrap py-1 pr-0 text-sm text-gray-900 text-right"
                      ></td>
                      <td
                        class="whitespace-nowrap py-1 text-sm text-gray-700"
                      ></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="lg:w-1/3 pl-7 pr-5 text-center">
            <div
              class="grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 m-0 p-0"
            >
              <a
                href="#"
                class="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
              >
                <div
                  class="flex md:h-full lg:flex-col"
                  @click="loadAyuda(true)"
                >
                  <div class="flex-shrink-0">
                    <div
                      class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-8 h-8"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    class="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4"
                  >
                    <div>
                      <p class="text-xs text-gray-900">
                        Información de esta pantalla
                      </p>
                    </div>
                  </div>
                </div>
              </a>

              <a
                href="#"
                class="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
              >
                <div
                  class="flex md:h-full lg:flex-col"
                  @click="loadModalExportarExcel(true)"
                >
                  <div class="flex-shrink-0">
                    <div
                      class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12"
                    >
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    class="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4"
                  >
                    <div>
                      <p class="text-xs text-gray-900">Exportar</p>
                    </div>
                  </div>
                </div>
              </a>

              <a
                href="#"
                class="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
              >
                <div
                  class="flex md:h-full lg:flex-col"
                  @click="loadExportarPdf(true)"
                >
                  <div class="flex-shrink-0">
                    <div
                      class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12"
                    >
                      <span class="text-semibold">PDF</span>
                    </div>
                  </div>
                  <div
                    class="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4"
                  >
                    <div>
                      <p class="text-xs text-gray-900">Reporte<br />Mensual</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--- menu nuevo-->

    <VistaHistorialCentrosCalendario
      v-if="1 == 2"
      :nombreFaena="this.nombreFaena"
      @loadAAMM="loadAAMM"
    >
    </VistaHistorialCentrosCalendario>
<div class="bg-white border-solid border-gray-300 border-2 p-2">
 Sin datos de generación.<br>
 Se muestran datos ficticios como muestra.
  </div>

    <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-1 overflow-scroll">
      <div class="p-1">
        <!-- consumo -->
        
        <HistorialMensual
          :aammConsulta="aammConsulta"
        ></HistorialMensual>
      </div>
      <div class="p-1">
        <HistorialDiarioOriginal 
        :medicionDiaria="medicionDiaria" 
        :aammConsulta="aammConsulta"
        :medicionAcumulada="medicionAcumulada"
        :diasMes="diasMes"  
        ></HistorialDiarioOriginal>
      </div>
     </div>


    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1">
      <div class="lg:flex">
        <div class="lg:w-1/2 pl-5 text-left">
          <h1 class="text-1xl font-extrabold text-left text-cyan-800">
            Seleccione fechas de consulta
          </h1>
          <CalendarioFaenas
            :fechasSeleccionadas="fechasSeleccionadas"
            @getFecCalendar="getFecCalendar"
          >
          </CalendarioFaenas>
        </div>

        <div class="lg:w-1/2 text-right pr-2"></div>
      </div>
    </div>

    <div class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 p-3">
    
      <VhCentrosO2
        :idEmpresa="this.empresa"
        :idFaena="this.faena" 
        :fechaConsulta="this.fechaConsulta"
        :arrayDias = "this.arrayDias"
      >
      </VhCentrosO2>
  

    </div>
    <div class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 p-3"> 
      
      <VhCentrosOrp
        :idEmpresa="this.empresa"
        :idFaena="this.faena" 
        :fechaConsulta="this.fechaConsulta"
        :arrayDias = "this.arrayDias"
      >
      </VhCentrosOrp>
    
    
    </div>
    
  </div>
</template>

<script>
 
import { MonthPickerInput } from "vue-month-picker";
import ExcelJS from "exceljs";
import { mapGetters } from "vuex";
import VistaHistorialCentrosCalendario from "./VistaHistorialCentrosCalendario";
import HistorialMensual from "./HistorialMensual";
 

 
import VhCentrosOrp from "./VhCentrosOrp";
import VhCentrosO2 from "./VhCentrosO2";
import CalendarioFaenas from "../calendar/CalendarioFaenas";
import HistorialDiarioOriginal from "./HistorialDiarioOriginal";
export default {
  components: {
    MonthPickerInput,
    VistaHistorialCentrosCalendario,
    HistorialMensual,
    
    HistorialDiarioOriginal,
    VhCentrosO2,
    VhCentrosOrp,
    CalendarioFaenas,
  
  },
  props: [
    "idEmpresa",
    "nombreFaena",
    "idFaena",
    "idTipoMedicion",
    "nombreIdTipoMedicion",
    "tituloY",
  ],
  mounted() {
   
    //console.log("id empresa:", this.empresa);
    //console.log("faena:", this.faena);
    //console.log("jaulas:", this.jaulas);
    //console.log("año:", this.defaultYear1);
    this.getFecCalendar(this.fechasSeleccionadas);
    const now = new Date();
    const mm =  now.getMonth() + 1;
    const aa =  now.getFullYear();
 
    this.diasMes = this.generaSerieDias(mm,aa);
  },
  data() {
    return {
      medicionDiaria: [
        1100, 1800, 2300, 2700, 3100, 2800, 3000, 2900, 2850, 2600, 2900, 2800,
        3100, 1900, 1750, 2050, 2000, 700, 800, 550, 900, 850, 650, 780, 940,
        1060, 650, 350, 0, 100, 100.1,
      ],
      medicionAcumulada: [
        0, 1.1, 2.9, 5.2, 7.9, 11, 13.8, 16.8, 19.7, 22.5, 25.2, 28.1, 31.0,
        34.1, 36, 38, 40, 42, 42.7, 43.5, 44.4, 45.3, 45.9, 46.7, 47.6, 48.5,
        49.6, 49.9, 49.9, 50, 50.1,
 
      ],
      diasMes: [],
      showPanelExportar: false,
      showPanelExportarExcel: false,
      showPanelAyuda: false,
      selectedLang: "es",
      defaultYear1: new Date().getFullYear(),
      defaultMonth1: new Date().getMonth()+1,
      minDate1: new Date("2022-06-01"),
      maxDate1: new Date(),
      mmConsulta : '',
      aaConsulta : '',
      fInicial : '',
      fFinal :'',
      fechasBusqueda:[],
      empresa: this.idEmpresa,
      faena: this.idFaena,
      Centro2: [],
      dataLoaded: false,
      jaulas: [],
      chartOptions: {
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: true,
          },
        },
        series: this.seriesFaenas,
        rangeSelector: {
          selected: 5,
          floating: true,
          y: 400,
        },
        title: {
          text: this.nombreIdTipoMedicion,
        },

        xAxis: {
          showLastLabel: true,
        },
        yAxis: {
          opposite: false,
          title: {
            text: this.tituloY,
          },
        },
      },
      nomMes: [
        "",
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      fechaConsulta: ["01 08 2024", "31 08 2024"],
      aammConsulta: "2024-8",
      fechasSeleccionadas: ["01 08 2024", "31 08 2024"],
      date: {
        from: null,
        to: null,
        month: null,
        year: null,
        monthIndex: null,
      },
      arrayDias: []
      //   from": "2023-01-01T03:00:00.000Z", "to": "2023-02-01T03:00:00.000Z", "month": "Enero", "monthIndex": 1, "year": 2023, "rangeFrom": null, "rangeTo": null, "rangeFromMonth": null, "rangeToMonth": null
    };
  },
  methods: {
    chgFec1(date) {
      this.titulo_fecha = date.month + " " + date.year;
      this.loadAAMM(date.year + "-" + date.monthIndex);
      //console.log(date.year + "-" + date.monthIndex);
      //this.$emit("loadAAMM",  date.year + "-" + date.monthIndex );
    },

    getDiasHastaHoy() {
      const now = new Date();
      const finDeMEs = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      const diaFinDeMes = finDeMEs.getDate();
      let arrayDias = [];
      
      for (let c = 1; c <= diaFinDeMes; c++) {
        arrayDias.push(c);
      }
      //console.log(arrayDias, "<---");
      return arrayDias;
    },
    generaSerieDias(mm,aa) {
     
      const diaFinDeMes = new Date(aa, mm, 0).getDate();
      let arrayDias = [];
      for (let c = 1; c <= diaFinDeMes; c++) {
        arrayDias.push(c);
      }
      //  this.fechasBusqueda = [ aa+'-'+ mm +'1',  aa+'-'+ mm +diaFinDeMes  ]
     
      return arrayDias;
    },
    generateDateArray(fechaInicio, fechaFin) {
            console.log(fechaInicio,"###")
            const inicio = new Date(fechaInicio);
            const fin = new Date(fechaFin);
            let arrayFechas = [];

            while (inicio <= fin) {
                let fecha = inicio.toISOString().split('T')[0]; // Formato "aaaa-mm-dd"
                arrayFechas.push(fecha);
                inicio.setDate(inicio.getDate() + 1); // Incrementa un día
                console.log(fecha,"###")
            }
          

            return arrayFechas;
      },
    showDate(date) {
      this.date = date;
      console.log('esta es la fecha:'+ date.month + "/ " + date.year )
      // construir fecha-formato compatible con  /api/mon/getConsolidatedData
      // 
      // El componente <month-picker-input> retorna: Marzo/ 2024 
 
      this.mmConsulta = this.nomMes.indexOf(  date.month.trim())
      this.aaConsulta = date.year
       
      // generar serie con dias del mes
      this.diasMes = this.generaSerieDias(this.mmConsulta ,this.aaConsulta)
      this.fechasBusqueda = [ this.fInicial , this.fFinal]
    
     
    },
  
   
    descargaPdf() {
      if (this.date.monthIndex == "11") {
        window.open("https://www.oxytek.cl/reporteswebmonitoreo/Demo_Reporte_PDF_Oxyplus_11.23.pdf", "_blank");
      }
     // if (this.date.monthIndex == "1") {
        window.open("https://www.oxytek.cl/reporteswebmonitoreo/Demo_Reporte_PDF_Oxyplus_01.24.pdf", "_blank");
      //}
    },
    loadExportarPdf(resp) {
      this.showPanelExportar = resp;
      this.showPanelExportarExcel = false;
    },
    loadModalExportarExcel(resp) {
      this.showPanelExportarExcel = resp;
      this.showPanelExportar = false;
      this.showPanelAyuda = false;
    },
    loadAyuda(resp) {
      //console.log("ayuda");
      this.showPanelAyuda = resp;
      this.showPanelExportar = false;
      this.showPanelExportarExcel = false;
    },
    loadAAMM(aamm) {
      this.aammConsulta = aamm;
    },
    getFecCalendar(f) {
      //console.log("calendario:", f);
      /* [ "01 10 2023",   "31 10 2023"]   */

      if (f[0] == "" || f[1] == "") return false;

      const startDate = this.convertirFormatoFecha(f[0]);
      const endDate = this.convertirFormatoFecha(f[1]);
      this.seriesFechas = this.generaSerieFechas(
        new Date(startDate),
        new Date(endDate)
      );
      
      this.fechaConsulta = f;
 
      this.arrayDias =  this.seriesFechas
  
    },
    convertirFormatoFechaDMA(fechaOriginal) {
      try {
        const partes = fechaOriginal.split("-");
        if (partes.length === 3) {
          const dia = partes[0];
          const mes = this.nomMes[Number(partes[1])];
          const año = partes[2];
          const nuevaFecha = `${año} ${mes} ${dia}`;
          return nuevaFecha;
        }
      } catch (error) {
        console.log(error);
      }
      return null;
    },
    convertirFormatoFecha(fechaOriginal) {
      
      if (!fechaOriginal) return false;

      const partes = fechaOriginal.split(" ");
      if (partes.length === 3) {
        const dia = partes[0];
        const mes = partes[1];
        const año = partes[2];

        const nuevaFecha = new Date(`${año}-${mes}-${dia}`);

        if (!isNaN(nuevaFecha)) {
          const fechaFormateada = nuevaFecha.toISOString().slice(0, 10);
          return fechaFormateada;
        }
      }

      return null;
    },
    generaSerieFechas(startDate, endDate) {
      const dates = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        let f = currentDate.toISOString().substring(0, 10);
        let pas = f.split("-")
        dates.push(pas[2]+ '-' + pas[1]);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    },
    manipulaSeriesFechas(min, max) {
      let dataFaena = [];
      for (let x = 0; x < this.faenas.length; x++) {
        let data = [];
        for (let i = 0; i < this.seriesFechas.length; i++) {
          let dma = this.convertirFormatoFechaDMA(this.seriesFechas[i]);
          //console.log(dma);
          let f = Date.parse(dma + " 00:00:00 GMT");
          //console.log(f);
          let n = parseFloat(this.getRnd(min, max));
          data.push([f, n]);
        }
        let medicion = { name: this.faenas[x].nombre, data: data };
        dataFaena.push(medicion);
      }
      return dataFaena;
    },

    async loadSeries(idFaena) {
      // oxigeno concentracion

      if (idFaena == 1) {
        return [
          "14 Jan 2023 00:00:00 GMT,22",
          "15 Jan 2023 00:00:00 GMT,24",
          "16 Jan 2023 00:00:00 GMT,23.2",
          "17 Jan 2023 00:00:00 GMT,24.1",
          "18 Jan 2023 00:00:00 GMT,25",
          "19 Jan 2023 00:00:00 GMT,23.69",
          "20 Jan 2023 00:00:00 GMT,19.41",
          "21 Jan 2023 00:00:00 GMT,22.01",
          "22 Jan 2023 00:00:00 GMT,18.12",
          "23 Jan 2023 00:00:00 GMT,12.92",
          "24 Jan 2023 00:00:00 GMT,12.05",
          "25 Jan 2023 00:00:00 GMT,26.98",
          "26 Jan 2023 00:00:00 GMT,39.8",
          "27 Jan 2023 00:00:00 GMT,21.89",
          "28 Jan 2023 00:00:00 GMT,23.91",
          "29 Jan 2023 00:00:00 GMT,26.14",
          "30 Jan 2023 00:00:00 GMT,22.14",
          "31 Jan 2023 00:00:00 GMT,27.99",
        ];
      }

      // console.log('obteniendo datos medicion oxigeno concentracion, jaula:1')

      return [
        "14 Jan 2023 00:00:00 GMT,22",
        "15 Jan 2023 00:00:00 GMT,23",
        "16 Jan 2023 00:00:00 GMT,23.2",
        "17 Jan 2023 00:00:00 GMT,24.1",
        "18 Jan 2023 00:00:00 GMT,23",
        "19 Jan 2023 00:00:00 GMT,22.69",
        "20 Jan 2023 00:00:00 GMT,23.41",
        "21 Jan 2023 00:00:00 GMT,21.01",
        "22 Jan 2023 00:00:00 GMT,26",
        "23 Jan 2023 00:00:00 GMT,22.92",
        "24 Jan 2023 00:00:00 GMT,22.05",
        "25 Jan 2023 00:00:00 GMT,26.98",
        "26 Jan 2023 00:00:00 GMT,19.8",
        "27 Jan 2023 00:00:00 GMT,21.89",
        "28 Jan 2023 00:00:00 GMT,24.91",
        "29 Jan 2023 00:00:00 GMT,25.14",
        "30 Jan 2023 00:00:00 GMT,23.14",
        "31 Jan 2023 00:00:00 GMT,23.99",
      ];
    },

    async exportarExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Datos");
      const tit = [["dia", "Diario Kg.Oxígeno", "Acumulado Ton.Oxígeno"]];
      tit.forEach((row) => {
        worksheet.addRow(row);
      });

      for (let i = 0; i < this.diasMes.length; i++) {
        try {
          worksheet.addRow([
            this.diasMes[i],
            this.medicionDiaria[i],
            this.medicionAcumulada[i],
          ]);
        } catch (error) {
          console.error(`Error al agregar fila ${i + 1}: ${error.message}`);
        }
      }

      const blob = await workbook.xlsx.writeBuffer();
      const url = URL.createObjectURL(new Blob([blob]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "exported.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },

    computed: {
      ...mapGetters(["user", "token"]),
    },
  },
};
</script>
