<template>
  <div>

    <div>

      <div class="bg-white m-2 p-2 rounded  col-span-2">

        <div class="md:flex md:items-center md:justify-between">
          <div class="min-w-0 flex-1  text-left pl-8">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            </h2>

            <h1 class="  text-1xl font-extrabold text-left text-cyan-800">
              Diario y Acumulado
            </h1>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4">

            <!--  <button type="button"
              class=" inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 
              text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Exportar</button>

              <button type="button" class="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 
              text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2
               focus:ring-indigo-500 focus:ring-offset-2">?</button>
               -->
          </div>
        </div>





        <SpinnerLoading v-show="result_mediciones !== true"></SpinnerLoading>
        <VueApexCharts @click="propagajaula" @mouseover="propagajaula" ref="demoChart" width="100%"
          :options="chartOptions" :series="series"></VueApexCharts>
      </div>
    </div>

    <div>

    </div>



  </div>
</template>
<script>
import { ref } from "vue";
import VueApexCharts from "vue3-apexcharts";
import SpinnerLoading from "../SpinnerLoading"
 
export default {
  props: ['aammConsulta', 'label', 'idJaula', 'dias', 'medicionDiaria', 'medicionAcumulada', 'diasMes'],
  emits: ['ampliaGrafico', 'muestraFoto'],
  components: {
    VueApexCharts, SpinnerLoading
  },
  data() {
    return {
      result_mediciones: true,
      fInicial: this.getFormattedCurrentDateTime(),
      fFinal: this.getFormattedEndOfMonthDateTime(),
      series: [
        {
          name: "Diario",
          type: 'line',
          data: ref(this.medicionDiaria)
          //data: [1.1,  1.8,  2.3, 2.7, 3.1, 2.8, 3.0, 2.9, 2.8,   2.6,   2.9,   2.8, 3.1 ]
        },
        {
          name: "Acumulado",
          type: 'area',
          data: ref(this.medicionAcumulada)

        }
      ],
      chartOptions: {
        chart: {
          height: 430,
          type: 'line',

          toolbar: {
            show: false
          }
        },
        colors: ['#3ca0fc', '#37E6A5'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth'
        },

        fill: {
          type: 'solid',
          opacity: 0.7,

        },
        title: {
          text: '',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns

          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: ref(this.diasMes),

        },
        yaxis: [
          {
            title: {
              text: 'Kilogramos de oxígeno'
            },
            min: 0,
            max: 3500,
            labels: {
              formatter: function (val) {
                return Math.floor(val)
              }
            }
          },
          {
            opposite: true,
            min: 0,
            max: 60,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#FF1654",
            },
            labels: {
              style: {
                colors: "#FF1654",
              }
            },

            title: {

              text: 'Toneladas de oxígeno',

              style: {
                color: "#FF1654",
              },
            },
          },]
      }


    }



  },
  methods: {
    getFormattedCurrentDateTime() {
      const now = new Date();
      return this.formatDate(now) + ' ' + this.formatTime(now);
    },
    getFormattedEndOfMonthDateTime() {
      const now = new Date();
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return this.formatDate(endOfMonth) + ' ' + this.formatTime(endOfMonth);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    formatTime(date) {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    },
    propagajaula() {
      this.$emit("muestraFoto", this.idJaula, this.label);
    },
    polldata() {

      this.polling = setInterval(() => {
        this.getdata()

      }, 20000)

    },
    async getdata() {
     
  

      this.result_mediciones = true;


    },
    ampliagrafico() {
      //console.log('amplia grafico:', this.idJaula, this.label)
      this.$emit("ampliaGrafico", this.idJaula, this.label);
    }
  },
  created() {

  },
  mounted() {


    this.getdata()
    this.polldata()

  },
  beforeUnmount() {
    clearInterval(this.polling)

  },
  watch: {
       

    }
};
</script>