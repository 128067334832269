<template>
  <div>
    

    <div className="grid grid-cols-1 grid-rows-1 gap-2 ml-2">
      <div class="pl-12">
        <h2 class="text-1xl font-bold tracking-tight text-black sm:text-4xl">
         
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
//import axios from "axios";

import { mapActions } from "vuex";
export default {
  name: "HomeSettings",
  props: ["idEmpresa"],
  emits: ["chgFaena", "chgFaenaCentros", "sendDivisionesMenuLat"],
  components: {
    
  },
  mounted() {
    this.datos_mensuales = [60, 20, 10, 10, 0];
    // this.loadData();
    this.loadDataSesion();
    this.enviaDivisionesMenuLat();
    this.seriesO2Loaded = true;
    this.seriesLoaded = true;
    this.getFecCalendar(this.fechasSeleccionadas);
  },
  data() {
    return {
      isDragStart: false,
      fechasSeleccionadas: ["15 06 2024", "01 07 2024"],
      mostrar: false,
      showModalMapa: false,
      showModalAyuda: false,
      showModalUsuarios: false,
      seriesLoaded: false,
      seriesO2Loaded: false,
      showPanelAyuda: false,
      datos_mensuales: [],
      faenas: [{}],
      cards: [{}],
      objFaenas: [{}],
      seriesFaenas: [],
      seriesFechas: [],
      nomMes: [
        "",
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      seriesMedicionesOxigeno1: [],
      seriesMedicionesOzono1: [],
    };
  },
  methods: {
    ...mapActions("draggdropp", [
      "droppableAdd",
      "setIdCurrentDroppable",
      "setPosCurrentDroppable",
    ]),
    loadModalAyuda(valor) {
      console.log("modifica estado modal");
      this.showModalAyuda = valor;
    },
    closeModalAyuda() {
      this.showModalAyuda = false;
    },
    loadAyuda(resp) {
      console.log("ayuda");
      this.showPanelAyuda = resp;
    },
    loadModalUsuarios(valor) {
      this.showModalUsuarios = valor;
    },
    loadDataSesion() {
      this.faenas = this.$store.state.login.userLogin.info.faenas;
    },

    enviaDivisionesMenuLat() {
      this.$emit("sendDivisionesMenuLat", this.faenas);
    },
    loadMapa() {
      console.log("LOAD MAPA");
      this.showModalMapa = true;
    },
    closeModal() {
      this.showModalMapa = false;
    },
    loadDivisiones(f) {
      this.$emit("chgFaena", f);
    },
    loadCentros(f) {
      this.$emit("chgFaenaCentros", f);
    },
    loadHistorial(f) {
      this.$emit("chgHistorialCentros", f);
    },
    getFecCalendar(f) {
      //  console.log('calendario', f);
      /* [ "01 10 2023",   "31 10 2023"]   */

      if (f[0] == "" || f[1] == "") return false;

      const startDate = this.convertirFormatoFecha(f[0]);
      const endDate = this.convertirFormatoFecha(f[1]);
      this.seriesFechas = this.generaSerieFechas(
        new Date(startDate),
        new Date(endDate)
      );
      this.seriesMedicionesOxigeno1 = this.manipulaSeriesFechas(4, 6);
      this.seriesMedicionesOxigeno2 = this.manipulaSeriesFechas(6, 8);
      this.seriesMedicionesOzono1 = this.manipulaSeriesFechas(100, 300);
      this.seriesMedicionesOzono2 = this.manipulaSeriesFechas(300, 700);
      this.mostrar = true;
    },

    manipulaSeriesFechas(min, max) {
      let dataFaena = [];

      /**
       seriesFechas
       *[
    "2023-10-01",
    "2023-10-02",
  ..
    "2023-10-29",
    "2023-10-30",
    "2023-10-31"
]
      */
      for (let x = 0; x < this.faenas.length; x++) {
        let data = [];
        for (let i = 0; i < this.seriesFechas.length; i++) {
          let dma = this.convertirFormatoFechaDMA(this.seriesFechas[i]);
          console.log(dma);

          let f = Date.parse(dma + " 00:00:00 GMT");
          let n = parseFloat(this.getRnd(min, max));
          data.push([f, n]);

          f = Date.parse(dma + " 04:00:00 GMT");
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n]);

          f = Date.parse(dma + " 08:00:00 GMT");
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n]);

          f = Date.parse(dma + " 12:00:00 GMT");
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n]);

          f = Date.parse(dma + " 16:00:00 GMT");
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n]);

          f = Date.parse(dma + " 20:00:00 GMT");
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n]);
        }
        let medicion = { name: this.faenas[x].nombre, data: data };
        dataFaena.push(medicion);
      }
      return dataFaena;

      //this.chartOptions.series  = dataFaena
    },
    generaSerieFechas(startDate, endDate) {
      const dates = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        dates.push(currentDate.toISOString().substring(0, 10));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    },

    convertirFormatoFecha(fechaOriginal) {
      if (!fechaOriginal) return false;

      const partes = fechaOriginal.split(" ");
      if (partes.length === 3) {
        const dia = partes[0];
        const mes = partes[1];
        const año = partes[2];

        const nuevaFecha = new Date(`${año}-${mes}-${dia}`);

        if (!isNaN(nuevaFecha)) {
          const fechaFormateada = nuevaFecha.toISOString().slice(0, 10);
          return fechaFormateada;
        }
      }

      return null;
    },

    convertirFormatoFechaDMA(fechaOriginal) {
      try {
        const partes = fechaOriginal.split("-");
        if (partes.length === 3) {
          const dia = partes[0];
          const mes = this.nomMes[Number(partes[1])];
          const año = partes[2];
          const nuevaFecha = `${año} ${mes} ${dia}`;
          return nuevaFecha;
        }
      } catch (error) {
        console.log(error);
      }
      return null;
    },

    getRnd(min, max) {
      let flot = Math.random() * (max - min + 1) + min;

      return flot.toFixed(2);
    },
    showModalHelp() {
      console.log("show modal");
      this.showModal = true;
    },
    handleDragOVer(event) {
      console.log("over");
      event.preventDefault();
    },
    handleDragEnter(event) {
      console.log("enter", event);
    },
    handleDrop() {
      console.log("DROP");
      this.$store.dispatch("droppableAdd");
      this.isDragStart = false;
    },
    handleDropFaena(id) {
      console.log("DROP en Faena", id);
      this.$store.dispatch("setPosCurrentDroppable", id);
    },
    handleDragFaena(id) {
      console.log("tomando faena ", id);
      this.$store.dispatch("setIdCurrentDroppable", id);
      this.isDragStart = true;
    },
    handleDragOver(event) {
      event.preventDefault();
    },
  },
  computed: {},
};
</script>
