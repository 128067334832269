<template>
  <div>




    <dl class="mt-5 grid grid-cols-12 gap-5 sm:grid-cols-12">
      <div class="col-span-3 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-2">Desde<br> {{ firstdate }}
      </div>
      <div class="col-span-6 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <button v-on:click="ampliagrafico" type="button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">Ampliar
          {{ label }} </button>
      </div>
      <div class="col-span-3 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-2">Hasta <br>{{ lastdate }}</div>
    </dl>


    <SpinnerLoading v-show="result_mediciones !== true"></SpinnerLoading>
    <VueApexCharts @click="propagajaula" @mouseover="propagajaula" ref="demoChart" width="100%" :options="chartOptions"
      :series="series"></VueApexCharts>

      <VueApexCharts @click="propagajaula" @mouseover="propagajaula" ref="demoChart" width="100%" :options="chartOptions2"
      :series="series2"></VueApexCharts>

  </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
import SpinnerLoading from "../SpinnerLoading"
import axios from "axios";
export default {
  props: ['label', 'idJaula', 'dias'],
  emits: ['ampliaGrafico','muestraFoto'],
  components: {
    VueApexCharts, SpinnerLoading
  },
  data: function () {
    return {
      result_mediciones: false,
      firstdate: '',
      lastdate: '',
      chartOptions: {
        chart: {
          type: "line",
          id: "vuechart-example-" + this.idJaula,
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            },
            autoSelected: 'zoom'
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#FF1654", "#247BA0"],
        xaxis: {
          categories: [],
        },
        markers: {
          size: 4
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#FF1654",
            },
            labels: {
              style: {
                colors: "#FF1654",
              },
            },
            title: {
              text: "pH",
              style: {
                color: "#FF1654",
              },
            },
          },
          {
            opposite: true,

            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#247BA0",
            },
            labels: {
              style: {
                colors: "#247BA0",
              },
            },
            title: {
              text: "ORP",
              style: {
                color: "#247BA0",
              },
            },
          },
        ],

      },
      chartOptions2: {
        chart: {
          type: "line",
          id: "vuechart-example2-" + this.idJaula,
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            },
            autoSelected: 'zoom'
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#22d3ee", "#701a75"],
        xaxis: {
          categories: [],
        },
        markers: {
          size: 4
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#22d3ee",
            },
            labels: {
              style: {
                colors: "#22d3ee",
              },
            },
            title: {
              text: "Concentración de Oxigeno",
              style: {
                color: "#22d3ee",
              },
            },
          },
          {
            opposite: true,

            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#701a75",
            },
            labels: {
              style: {
                colors: "#701a75",
              },
            },
            title: {
              text: "Saturación de Oxigeno",
              style: {
                color: "#701a75",
              },
            },
          },
        ],

      },
      series: [
        {
          name: "pH",
          data: [],
        },
        {
          name: "ORP (mV)",
          data: [],
        }
      ],
      series2: [
        {
          name: "Concentración de Oxígeno (mg/l)",
          data: [],
        },
        {
          name: "Saturación de Oxígeno (%)",
          data: [],
        }
      ],
    };
  },
  methods: {
    propagajaula() {
      this.$emit("muestraFoto", this.idJaula, this.label);
    },
    polldata() {

      this.polling = setInterval(() => {
        this.getdata()

      }, 20000)

    },
    async getdata() {
      console.log('obteniendo datos jaula', this.idJaula)
      
      let categorias = [];
      try {
       
        if(! this.idJaula) {return false}
        const response = await axios.get("http://localhost:8080/api/Mediciones/GetMedicionesByIdJaulaByIdTipoMedicion?idJaula=" + this.idJaula + "&idTipoMedicion=2");

        let updatedData = [];

        updatedData = response.data = [...response.data].splice(0, 20);
        // updatedData = response.data  
        let mediciones = []


        updatedData.forEach((mobile) => {
          // console.log(mobile["fechaMedicion"])
          //  console.log(mobile["medicion"])
          //console.log(mobile["fechaMedicion"])
          let fmedicion = mobile["fechaMedicion"];
          if (this.firstdate.length == 0) {
            let paso = fmedicion.substring(0, 9);
            paso = paso.split("-")
            paso[2]= parseInt(paso[2]) < 10 && `0${paso[2]}`;
            let aa = paso[2] + "-" + paso[1] + "-" + paso[0]
            this.firstdate = aa + " " + fmedicion.substring(11);
          }

          let paso = fmedicion.substring(0, 9);
          paso = paso.split("-")
          paso[2]= parseInt(paso[2]) < 10 && `0${paso[2]}`;
          let aa = paso[2] + "-" + paso[1] + "-" + paso[0]
          this.lastdate = aa + " " + fmedicion.substring(11);




          let minuto_segundo = fmedicion.substring(fmedicion.length - 2);
          categorias.push(minuto_segundo);


          mediciones.push(mobile["medicion"].toFixed(2));

        });


        this.chartOptions = {
          xaxis: { categories: categorias }
        }
        this.series[0].data = mediciones

      } catch (err) {
        console.log(err);
      }



      try {
        const response = await axios.get(
          "http://localhost:8080/api/Mediciones/GetMedicionesByIdJaulaByIdTipoMedicion?idJaula=" +
          this.idJaula +
          "&idTipoMedicion=4"
        );
        let mediciones2 = [];
        let updatedData = [];
        updatedData = response.data = [...response.data].splice(0, 20);
        //updatedData = response.data  
        updatedData.forEach((mobile) => {
          // console.log(mobile["medicion"])
          let medicion = mobile["medicion"].toFixed(2)
          mediciones2.push(medicion);
        });
        this.series[1].data = mediciones2


      } catch (err) {
        console.log(err);
      }

      try {
        const response = await axios.get(
          "http://localhost:8080/api/Mediciones/GetMedicionesByIdJaulaByIdTipoMedicion?idJaula=" +
          this.idJaula +
          "&idTipoMedicion=5"
        );
        let mediciones2 = [];
        let updatedData = [];
        updatedData = response.data = [...response.data].splice(0, 20);
        //updatedData = response.data  
        updatedData.forEach((mobile) => {
          // console.log(mobile["medicion"])
          let medicion = mobile["medicion"].toFixed(2)
          mediciones2.push(medicion);
        });
        this.series2[0].data = mediciones2


      } catch (err) {
        console.log(err);
      }

      try {
        const response = await axios.get(
          "http://localhost:8080/api/Mediciones/GetMedicionesByIdJaulaByIdTipoMedicion?idJaula=" +
          this.idJaula +
          "&idTipoMedicion=6"
        );
        let mediciones2 = [];
        let updatedData = [];
        updatedData = response.data = [...response.data].splice(0, 20);
        //updatedData = response.data  
        updatedData.forEach((mobile) => {
          // console.log(mobile["medicion"])
          let medicion = mobile["medicion"].toFixed(2)
          mediciones2.push(medicion);
        });
        this.series2[1].data = mediciones2


      } catch (err) {
        console.log(err);
      }

      this.result_mediciones = true;


    },
    ampliagrafico() {
      console.log('amplia grafico:', this.idJaula, this.label)
      this.$emit("ampliaGrafico", this.idJaula, this.label);
    }
  },
  created() {

  },
  mounted() {


    this.getdata()
    this.polldata()

  },
  beforeUnmount() {
    clearInterval(this.polling)

  }
};
</script>