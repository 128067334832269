 
<template>
    
     <div  v-for="(item, jaula) in arrayJaulas" :key="item.id">
     
    {{jaula.label}}  

    <GraficoLineal @ampliaGrafico="ampliaGrafico" @muestraFoto="ampliaFoto" :label="item.label" :idJaula="item.id" :idTren="idTren"  ></GraficoLineal>
    </div>
 
</template>

<script>
 
 
import GraficoLineal from "./GraficoLineal.vue";
export default {
  props: [
    "idDivision",
    "idJaula",
    "jaulas",
    "jaulasnombres",
    "nombreDivision",
    "titulo",
    "idTren"
  ],
  emits: ["ampliaGrafico","muestraFoto"],
  data() {
    return {
      loading: 'loading',
      categorias: [],
      medicion: [],
      medicion2: [],
      categorias1: [],
      mediciones1: [],
      mediciones2: [],
      arrayJaulas:[],
      
    };
  },

  components: {GraficoLineal},
  methods: {
   
    ampliaGrafico(idJaula, label) {
       console.log("ampliaGrafico 2 :",idJaula);
      this.$emit("ampliaGrafico",  idJaula, label);
    },
     ampliaFoto(idJaula, label) {
       console.log("muestraFoto 2 :",idJaula);
      this.$emit("muestraFoto",  idJaula, label);
    },
  },
  mounted() {
    
  
    
    if (this.idJaula == "" && this.jaulas!=="") {
 
      let jaulas = this.jaulas.split(",");
      let jaulasnombres = this.jaulasnombres.split(",");
      for (let c = 0; c < jaulas.length; c++) {
        
          if(jaulas[c].length>0){
          let jaula=jaulas[c]
          let jaulanombre=jaulasnombres[c]
          this.arrayJaulas.push({
            "id":jaula,
            "label":jaulanombre
          })
          }
        
      }
    }  
  },
  beforeUnmount(){
   
    console.log('desmontado');
     
  }
};
</script>