<template>



  
    <div v-if="showModal"  class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <!--
        Background backdrop, show/hide based on modal state.
    
        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
     
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <!--
            Modal panel, show/hide based on modal state.
    
            Entering: "ease-out duration-300"
              From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100 translate-y-0 sm:scale-100"
              To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          -->
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-screen-lg">
            <div>
              <div class="text-center">
              <h1 class="mt-3 text-3xl font-extrabold tracking-tight text-slate-900">
              {{titulo }} </h1>
              </div>

              <div class="text-left">
               <b> Natural </b><br>
Registro de niveles de oxígeno u ORP medidos fuera del módulo de cultivo, por el sensor de control ubicado bajo la plataforma Oxyplus.
<br><br>
<b>Oxigenado / Ozonizado</b>
<br>
Promedio diario de todos los sensores de oxígeno u ORP ubicados dentro de las jaulas de cultivo, habilitados durante el periodo en consulta. 
Cada curva representa el promedio diario por sistema o centro de cultivo.
<br>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <button @click="close_modal" type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </template>
      
      
      <script>
     
      export default {
        
        beforeMount(){
            this.titulo = this.texto
        },
        props: ["showModal", "mensaje", "seriesFaenas","texto"],
        emits: ["close"],
        data: () => ({
          titulo:'',
            visible:false,
          options: {
            chart: {
      
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: [
                "Nov 2022",
                "Dic 2022",
                "Ene 2023",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
      
              ]
            },
            yaxis: {
              title: {
                text: ' Toneladas'
              }
            },
            tooltip: {
                    y: {
                      formatter: function (val) {
                        return   val + " Toneladas "
                      }
                    }
                  }
          },
          series: [{
            name: 'Tren de Jaulas A',
            data: [124.1, 125, 93.9]
          },
          {
            name: 'Tren de Jaulas B',
            data: [125, 94.3, 124.2]
          }]
        }),
      
      
      
        methods: {
          delete_usuario() {
      
            this.$emit("delete")
      
          },
          close_modal() {
            console.log('cerrar modal 1');
            this.$emit("close")
          }
      
        }
      }
      </script>