<template>
    <div>

       

            <div class="container-generador flex flex-wrap w-full mt-4  bg-white  shadow-lg rounded-lg p-3">
                
                <!-- Segunda columna: indicador -->
                <div class="col1 w-full lg:w-1/3 h-100 flex justify-center items-center">
                    <span :class="colorClassGen" class="ml-4 w-6 h-6  rounded-full"></span> 
                  </div>
                <!-- Tercera columna: datos -->
                <div class="col2 w-full lg:w-1/3 flex flex-wrap lg:flex-row">
                    <div class="flex w-full lg:w-10/10" v-for="obj in objeto" :key="obj.id">
                        <div class="valor w-full lg:w-auto text-sm">{{obj.valor}}</div>
                        <div class="caption w-full lg:w-5/10 text-sm ">{{obj.ume}}:</div>
                    
                    </div>
                    
                </div>
            </div>
 
    </div>
</template>

<script>
 
export default {
    name: 'ComponentePlanta',
    props: ['setup','datos', 'estado'],
    computed: {
    colorClassGen() {

      return this.estado == "1" ? 'bg-green-500' : 'bg-gray-500'
    },
    //...mapState('auth',['userLogin']),
  } ,
    data() {
        return {
           status:0,
           medicion:'',
           valor:'',
           objeto: [],
           data: []
        };
    },
    created() {
     
    },
    mounted(){
        this.data = this.datos;
        this.crearObjeto(this.data);
   
    },
    methods: { 
        crearObjeto(data){
            this.objeto =[]
             if( Array.isArray(this.setup.medicion) && this.setup.medicion !== null )
             {
                console.log('es array',this.setup.medicion.length)
                for (let i = 0; i < this.setup.medicion.length; i++) {
                        let valor = Array.isArray(data) && data !== null ? data[i] : 0
                        this.objeto.push(   {'indice':i, 'ume':this.setup.medicion[i],    'valor':valor})
                } 

             }
           
        }
    }, 
    watch: {
        datos: function (newdata) {
            this.data = newdata;
            this.crearObjeto(this.data)
       } 
  }
    
};
</script>

<style scoped></style>