// divisiones.js

const state = {
  faenas: [],
};
const getters = {
  getFaenas: (state) => {
    return state.faenas;
  }
}
const mutations = {
  setFaenas(state, faenas) {
    state.faenas = faenas;
  },
};

const actions = {
  setFaenas({ commit }, listFaenas) {
    commit("setFaenas", listFaenas);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
