<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div>



        <div class="grid-cols-12  sm:grid-cols-12 bg-white">
             
              {{idTren}}
                <img :src="getImgUrl()" >
            
        </div>
    </div>
</template>
 
<script>
import { mapState } from 'vuex';
 
export default {
    props: ["nombreDivision","jaulas", "jaulasnombres", "saludo","idJaula","idTren"],
    data() {
        return {
            contexto:"",
            urlfoto:"",
            arrayNomJaulas: this.jaulasnombres,
            arrayIdJaulas: this.jaulas,
            idEmpresa:'',
            projects: [
                // { name: 'Jaula 4', initials: 'J4', href: '#', members: 16, bgColor: 'bg-pink-600' },
                // { name: 'Jaula 1', initials: 'J1', href: '#', members: 12, bgColor: 'bg-purple-600' },
                // { name: 'Jaula 2', initials: 'J2', href: '#', members: 16, bgColor: 'bg-yellow-500' },
                // { name: 'Jaula 3', initials: 'J3', href: '#', members: 8, bgColor: 'bg-green-500' },
            ]

        }
    },
    methods: {
        getImgUrl(){
          
            let images = require.context('../../assets/layouts/', false, /\.jpg$/)
            if(!this.idTren){
                 return images('./vacio.jpg')
            }
            if(!this.idJaula){
                 return images('./vacio.jpg')
            }
            let idJaulaSeleccionada = this.idJaula ? this.idJaula :0  
              console.log("idJaulaSeleccionada:",idJaulaSeleccionada)
            return images('./' + this.user.idEmpresa+'-'+this.idTren+'-'+idJaulaSeleccionada + ".jpg")
        },
        dibuja() {
            this.projects = []
            let jaulas = this.jaulas.split(",");
            let jaulasnombres = this.jaulasnombres.split(",");

            for (let c = 0; c < jaulas.length; c++) {


                let jaula = jaulas[c]
                let jaulanombre = jaulasnombres[c]
                if(jaulanombre!==''){
                this.projects.push({
                    "initials": jaula,
                    "name": jaulanombre,
                    "bgColor": 'bg-green-500'
                })
                }

            }


        },
    },

    watch: { // It listens to the change in prop name
        jaulas: function () {
            console.log("jaulas cambiaron change");  
            this.dibuja()

        },
        idTren:function(){
             console.log("cambio Tren",this.idTren); // print out when the name changes
        }
    },
    mounted(){
       
            this.dibuja()
    },
    beforeUnmount() {

        console.log('desmontado mapa trenes');

    },
    computed: {
        ...mapState([
            'user',
        ])
       
    },
}

</script> 