<template>
  
  <div>
 

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-6">
      <div class="col-span-2 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt class="text-sm font-medium text-gray-500 truncate"> Faena </dt>
        <dd class="font-semibold text-slate-900">{{ nombreFaena }} </dd>
      </div>

      <div class="col-span-3 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt class="text-sm font-medium text-gray-500 truncate"> Seleccionado </dt>
        <dd class="  "> </dd>
        <dd class="  "> {{ nombreDivisionPadre }} </dd>

      </div>

      <div class="col-span-1 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt class="text-sm font-medium text-gray-500 truncate"></dt>
        <dd class="mt-1 text-3xl font-semibold text-gray-900">
          <button type="button" @click="ExitArbol"
            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Salir</button>

        </dd>
      </div>
    </dl>
  </div>


  <div class="container mx-auto">


    <div class="grid grid-cols-2  mt-4 md:grid-cols-2 lg:grid-cols-3 gap-6">

      <div class="  bg-white shadow rounded-lg overflow-hidden col-span-1 p-6 ">
        <Tree :hideGuideLines="false" :expandable="false" v-model:nodes="datadivisiones" @nodeClick="onNodeClick"
          :use-icon="true" />
      </div>
      <div class=" p-6 bg-white shadow rounded-lg overflow-hidden col-span-2">
Tren {{ idTren }}
        <MedicionDivision v-if="division" :key="componentKey" @ampliaGrafico="chgGrafico"  @muestraFoto="chgFoto"  :idJaula="idJaula" :idTren="idTren"
          :jaulas="jaulas" :jaulasnombres="jaulasnombres" :nombreDivision="nombreDivision" />
      </div>
    </div>
  </div>
  
  <footer  class="fixed bottom-0 right-0 ">
      
       <MapaTrenes v-if="division"  :idTren="idTren" :idJaula="jaulaverfoto" :nombreDivision="nombreDivision" :jaulas="jaulas"  :jaulasnombres="jaulasnombres" />
    </footer>
</template>

 <style scoped>
  footer{
    z-index:5000;
    width:300px;
    height:200px;
  } 
 </style>


<script>

import MapaTrenes from "./MapaTrenes"
import Tree from "vue3-tree";
import MedicionDivision from "./MedicionDivision";

import "vue3-tree/dist/style.css";
export default {
  name: "ArbolDivisiones",
  props: ["datosUsuario", "idFaena", "nombreFaena", "datadiv", "trenJaulas"],
  emits: ["ampliaGrafico", "exitArbol"],
  components: {
    Tree, MedicionDivision,   MapaTrenes
  },

  data() {
    return {
      divisiones: [{}],
      datadivisiones: this.datadiv,
      currentDivision: [{}],
      division: false,
      idDivision: 0,
      idTren: 0,
      idJaula: 0,
      jaulas: '',
      jaulasnombres: '',
      componentKey: 0,
      jaulaseleccionada: 0,
       jaulaverfoto: 0,
      trenseleccionado: 0,
      nombreDivisionPadre: "",
      nombreDivision: "",

    };
  },
  methods: {

    loadFaenas() {
      this.$emit("chgDivision", false);
    },
    ExitArbol() {
      this.$emit("exitArbol", false);
    },
    onNodeClick(node) {
      this.estGrafico = 0
      this.idDivision = this.idTren = this.idJaula = 0;

      this.idDivision = typeof (node.idDivision) !== 'undefined' ? node.idDivision : false;
      this.idTren = typeof (node.idTren) !== 'undefined' ? node.idTren : false;
      if (typeof (node.idJaula) !== 'undefined' && node.idJaula !== null) {
        this.idJaula = node.idJaula;
        // esta comprobación es para evitar multiples llamadas al API.
        // Si ya fue seleccionado el nodo, aunque le vuelva a hacer click
        // ya no hace la llamada http
        // Si se seleciona otro nodo, se actualiza la variable 
        // this.jaulaseleccionada
        if (this.idJaula !== this.jaulaseleccionada) {

          this.division = true
          this.nombreDivision = node.label
          this.componentKey = node
          this.jaulaseleccionada = this.idJaula
          this.trenseleccionado = 0;
          let objIndex = this.trenJaulas.findIndex((obj => obj.idTren == node.idTren));
          if (objIndex > 0) {

            this.nombreDivisionPadre = this.trenJaulas[objIndex].divisionPadre;
          }

          console.log(node)
          this.cargaVistaGrafico()
        }
      }


      if (node.tipo == 'Tren') {
        let objIndex = this.trenJaulas.findIndex((obj => obj.idTren == this.idTren));
        if (objIndex > 0) {
          this.jaulas = this.trenJaulas[objIndex].jaulas;
          this.jaulasnombres = this.trenJaulas[objIndex].jaulasnombres;
          this.nombreDivisionPadre = this.trenJaulas[objIndex].divisionPadre;
        }

        if (this.idTren !== this.trenseleccionado) {
          //   console.log('muestra grafico tren',this.nombreDivisionPadre)
          this.division = true
          this.nombreDivision = node.label
          this.componentKey = node
          this.trenseleccionado = this.idTren
        }

      }



    },



    cargaVistaGrafico() {
      // console.log('nombre padre',this.nombreDivisionPadre)
      let objDivision = {
        id: this.jaulaseleccionada,
        nombre: this.nombreDivision,
        nombreDivisionPadre: this.nombreDivisionPadre
      };
      this.$emit("ampliaGrafico", objDivision);

    },

    chgGrafico(idJaula, label) {

      let objDivision = {
        id: idJaula,
        nombre: label,
        nombreDivisionPadre: this.nombreDivisionPadre
      };
      this.$emit("ampliaGrafico", objDivision);
    },
    chgFoto(idJaula, label){
        console.log(label)
        this.jaulaverfoto=idJaula
    }

    /*
     async loadData() {
       try {
         const resp =
           await axios.get(
             "http://localhost:8080/arbol-niveles/jerarquias.json"
           );
         this.midata = resp.data;
 
       } catch (err) {
         console.log(err);
       }
     },*/

  },

  setup() {




    return {


    };




  },
  mounted() {
    // this.loadData();
    //console.log("JERARQUIA", this.trenJaulas);
    this.estGrafico = 0;


  }


};
</script>
 
   