<template>
  <div>
      <ModalHelp :showModal="showModal" :texto="nombreIdTipoMedicion" @close="closeModalHelp"> </ModalHelp>
      
      <div class="md:flex md:items-center md:justify-between  bg-white ">
          <div class="min-w-0 flex-1  text-left pl-8">
              <h1 class="mt-3 text-1xl font-extrabold tracking-tight text-cyan-800">
                  {{ nombreMedicion }}  {{  nombreIdTipoMedicion}}    
              </h1>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4">

              <span class="isolate inline-flex rounded-md shadow-sm   p-3">
                  <button type="button" @click="chgTipo(1)"
                        :class="{  
                        'border-gray-500 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500': natural,
                        '': !natural,
                        'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10': true }">Natural</button>
                
                       <button type="button" @click="chgTipo(2)"
                      :class="{  
                        'border-purple-500 focus:border-purple-500 focus:outline-none focus:ring-1 focus:ring-purple-500': ozonizado,
                        '': !ozonizado,
                       'relative -ml-px inline-flex items-center rounded-r-md border bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 ': true }">Ozonizado</button>
              </span>
              <span class="isolate inline-flex rounded-md shadow-sm ml-12 p-3">
                  <button type="button" @click="loadMapa(f)"
                      class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">Mapa</button>
                  <button type="button" @click="showModalHelp()"
                      class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">?</button>
              </span>





          </div>
      </div>
      <div class="bg-white shadow-lg">
          <highcharts :constructor-type="'stockChart'" :options="chartOptions"></highcharts>
      </div>
  </div>
</template>
<script>
//import axios from "axios";

import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import ModalHelp from './modal/ModalHelp'
stockInit(Highcharts)
Highcharts.setOptions({
    chart: {
        
        
    },
    lang: {
        months: [
            'Ene', 'Feb', 'Mar', 'Abr',
            'May', 'Jun', 'Jul', 'Ago',
            'Sep', 'Oct', 'Nov', 'Dic'
        ],
        weekdays: [
            'Dom', 'Lun', 'Mar', 'Mie',
            'Jue', 'Vie', 'Sab'
        ]
    }
});

export default {
  components: {
      highcharts: Chart,
      ModalHelp
  },
  emits: ['loadMapa'],
  props: [  "seriesMediciones1", "seriesMediciones2",  "idMedicion", "nombreMedicion", "tituloY"],

  data() {
      return {
          natural: false,
          ozonizado:false,
          idTipo:0,
          nombreIdTipoMedicion:'',
          mediciones: [],
          showModal: false,
          Centro2: [],
          chartOptions: {
              legend: {
                  enabled: true,

                  layout: 'vertical',
                  align: 'right',
                  verticalAlign: 'middle'

              },

              plotOptions: {
                  line: {
                      dataLabels: {
                          enabled: false
                      },
                      enableMouseTracking: true
                  },

                  series: {
                      showInNavigator: true
                  }
              },


              series: [],

              navigator: {
                  series: {

                      lineWidth: 2,
                      fillOpacity: 0.04,
                      dataGrouping: {
                          smoothed: false
                      },
                  }
              },
              rangeSelector: {
                  selected: 2,
                  floating: true,
                  y: 400,


              },
              title: {
                  text: ""
              },


              xAxis: {
                  showLastLabel: true
              },
              yAxis: {
                  opposite: false,
                  title: {
                      text: this.tituloY
                  },
                    min: 0,
                   max: 900
              }
          },


      }
  },
  watch: {
      seriesMediciones2: function (nuevoValor) {
     
          console.log(`'miProp' cambió de  ${nuevoValor}`);
          // Puedes realizar otras acciones aquí en respuesta al cambio
           this.idTipo= 2 ;
           this.chartOptions.series = nuevoValor;
                 this.natural=false;
                 this.ozonizado=true;     
              
      },
      ozonizado:function(nuevoValor){
          if(nuevoValor){
              this.nombreIdTipoMedicion =  "Ozonizado";
          }else{
              this.nombreIdTipoMedicion =  "Natural";
          }
      }

  },


  methods: {
      chgTipo(tipo){   
                
                  if(tipo==1){
                      console.log(tipo)
                      this.chartOptions.series  =  this.seriesMediciones1
                   
                      this.natural=true;
                      this.ozonizado=false;
                  }else{
                      this.chartOptions.series   =  this.seriesMediciones2
                    
                      this.natural=false;
                      this.ozonizado=true;
                  }
               
                  this.idTipo= tipo ;
                  this.nombreIdTipoMedicion = this.idTipo=="1"  ? "Natural" : "Oxigenado";
                  
      },
      showModalHelp() {
          console.log('show modal')
          this.showModal = true
      },
      closeModalHelp() {
          console.log('show modal')
          this.showModal = false
      },
      loadMapa(f) {
          console.log("load mapa ");
          this.$emit("loadMapa", f);
      },

      



  },

  mounted() {

      this.idTipo= 2 ;
     this.chartOptions.series = this.seriesMediciones2;
     console.log('montando')
     this.natural=false;
     this.ozonizado=true;
  
     
      //  this.manipulaSeriesNatural()
     


  },
}
</script> 
