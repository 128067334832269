<template>
  <div>
    <ModalLogin
      :show="showModal"
      :error="error"
      @close="showModal = false"
    ></ModalLogin>
    <div class="w-full h-10 bg-black lg:fixed lg:inset-y-0">
      <!-- Contenido de tu div -->
    </div>
    <div
      class="bg-cover bg-center w-full min-h-screen flex items-center justify-center"
      style="
        background-image: url('https://www.oxytek.cl/images/clouds-intro.jpg');
      "
    >
      <div class="min-h-full flex flex-col">
        <div class="m-1 lg:mt-12 xl:mt-6 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="flex items-center justify-center text-center ">
          <img
            class="w-60"
            src="https://www.oxytek.cl/images/logo-intro-ingenieria-3t.png"
            alt="Oxytek"
          />
          </div>
        </div>

        <div class="pt-1 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-6 lg:px-6">
       
            <div class="flex items-center justify-center text-center p-3 text-blue-800">
              {{ this.mensaje }}
             </div> 
            <form class="space-y-6" @submit.prevent="handleSubmit">
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                  >Email</label
                >
                <div class="mt-1">
                  <input
                    id="email"
                    v-model="email"
                    name="email"
                    type="text"
                    autocomplete="email"
                    required=""
                    class="text-lg appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div class="mt-1">
                  <input
                    id="password"
                    v-model="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required=""
                    class="text-lg appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>

               
           
                <div class="flex items-center justify-center text-center p-3 text-blue-800">
                  <vue-recaptcha
                    v-show="showRecaptcha"
                    sitekey="6LdgnAcpAAAAAJHBHchPcK5qJjGc1oEa55pFpJTr"
                    size="normal"
                    theme="light"
                    hl="es"
                    :loading-timeout="loadingTimeout"
                    @verify="recaptchaVerified"
                    @expire="recaptchaExpired"
                    @fail="recaptchaFailed"
                    @error="recaptchaError"
                    ref="vueRecaptcha"
                  ></vue-recaptcha>
                </div>
             
              <div>
                <button
                  type="submit"
                  class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Entrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import router from "../../router";
import ModalLogin from "./ModalLogin";
import DOMPurify from "dompurify";
import vueRecaptcha from "vue3-recaptcha2";

export default {
  name: "LoginRootCaptcha",
  components: {
    vueRecaptcha,
    ModalLogin,
  },
  mounted() {
    window.localStorage.removeItem("user");
  },
  data() {
    return {
      showRecaptcha: true,
      loadingTimeout: 30000, // 30 seconds
      email: "",
      password: "",
      showModal: false,
      status: "0",
      token: "",
      mensaje: "",
      verificado: false,
      keycliente: "6LdgnAcpAAAAAJHBHchPcK5qJjGc1oEa55pFpJTr",
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),
    updModal() {
      this.showModal = true;
    },
    async handleSubmit() {
      if (!this.verificado) {
        this.error = "Error. Debe marcar CAPTCHA";
        this.updModal();
        return false;
      }

      try {
        this.mensaje = " Ingresando a sitio de monitoreo ...";

        let e = DOMPurify.sanitize(this.email);
        let p = DOMPurify.sanitize(this.password);
        await this.$store.dispatch("login", { e, p });
        if (this.$store.state.login.statusLogin == 200) {
          console.log(this.mensaje);
          router.push("/home");
        } else {
          this.error = "Error. Datos de acceso no válidos";
          this.updModal();
        }
      } catch (err) {
        this.error = " Error interno del servidor";
        this.updModal();
        console.log(err);
        this.mensaje = err;
      }
    },
    async recaptchaVerified(response) {
      console.log("respuesta", response);

      const url = "/verify-recaptcha";
      const token = response;
      const clave = this.keycliente;
      const data = {
        Secret: clave,
        respuesta: token,
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Respuesta del servidor OK:", data);
          this.verificado = true;
          // Realizar acciones adicionales con la respuesta del servidor
        })
        .catch((error) => {
          console.error("Error al realizar la solicitud POST:", error);
          this.verificado = false;
          // Manejar errores
        });

      
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {},
    recaptchaError(reason) {
      console.log("reason", reason);
    },
  },
};
</script>
