<template>
    <div> 
     <iframe width="988" height="532" frameborder="0" 
     src="https://www.bing.com/maps/embed?h=532&w=988&cp=-41.71905551577858~-72.54135131841116&lvl=11&typ=d&sty=r&src=SHELL&FORM=MBEDV8" scrolling="no">
     </iframe>
     <div style="white-space: nowrap; text-align: center; width: 100%; padding: 6px 0;">
        <a  class="text-xs" id="largeMapLink" target="_blank" href="https://www.bing.com/maps?cp=-41.71905551577858~-72.54135131841116&amp;sty=r&amp;lvl=11&amp;FORM=MBEDLD">Ver mapa más grande</a> &nbsp; | &nbsp;
        <a class="text-xs" id="dirMapLink" target="_blank" href="https://www.bing.com/maps/directions?cp=-41.71905551577858~-72.54135131841116&amp;sty=r&amp;lvl=11&amp;rtp=~pos.-41.71905551577858_-72.54135131841116____&amp;FORM=MBEDLD">Obtener indicaciones</a>
    </div>
 
    </div>
</template>
<script>
 
export default{
    
}
</script>