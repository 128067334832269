<template>
    <div> 
   
      <div class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1   pt-4 ">
          
          <span class="text-2xl text-white font-bold">Historial  {{nombreFaena}}   </span>
  
       </div>     
  
   </div>
  </template>
  <script>
   
   
  export default {
   
    props:['nombreFaena'],
    components:{
    },
    data(){
    },
    methods:{
    }
  }
  </script>