import { createApp } from 'vue'
import App from './App.vue'

import './index.css'
import router from './router'
import store from './store'
 
 

 
 
// treat all tags starting with 'ion-' as custom elements

createApp(App).use(router ).use(store).mount('#app')
