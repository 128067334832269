<template>
  <div class="p-0 mt-3">
   
    <VueTailwindDatepicker  i18n="es"   :shortcuts="false" :formatter="formatter"
      v-model="dateValue" />
       
      
  </div>
</template>
    

<script>
import { ref } from 'vue'
import VueTailwindDatepicker from 'vue-tailwind-datepicker'


export default {
  components: {
    VueTailwindDatepicker
  },
  props: ['fechasSeleccionadas'],
  emits:['getFecCalendar'],
  mounted(){
 
  },
  data() {
    return {
     
        checkin: '',
    
   
      dateValue: ref(this.fechasSeleccionadas ),
      formatter: ref({
        date: 'DD MM YYYY',
        month: 'MMM',

      }),
    

    }
  },
  watch: {
    fechasSeleccionadas(value){
      this.dateValue=value
    },
    dateValue() {
     
      this.getFecCalendar();
    },
  },
  methods: {
    getFecCalendar() {
   
      console.log(this.dateValue,"<--");
      this.$emit("getFecCalendar",this.dateValue)
    
     
    },
  },
}
</script>