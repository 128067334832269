
<script>
 
import router from "../router";
 
 
export default {
  name: "LogoutSession",
  mounted(){
    this.$store.dispatch('login', {});
    window.localStorage.removeItem('user');
    router.push("/");
  }, 
};
</script>
