<template>
  <div>
  
    <div v-if="showPanelExportar" style="z-index:2000" class="relative z-10" aria-labelledby="slide-over-title"
      role="dialog" aria-modal="true">
      <!-- Background backdrop, show/hide based on slide-over state. -->
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>


      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 ">
           
            <div class="pointer-events-auto  w-screen max-w-md">
              <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <div
                      class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                      <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                          d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
                      </svg>
                    </div>
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Generar reporte en PDF</h2>

                    <div class="ml-3 flex h-7 items-center">
                      <button @click="loadExportarPdf(false)" type="button"
                        class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                        <span class="sr-only">Close panel</span>
                        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                          aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">

                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div class="absolute inset-0 py-6 pt-8 sm:px-6">
                    <div class="h-full border-2 border-gray-200 " aria-hidden="true">



                      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 pl-8">

                        <div class="bg-white   align-right mb-4 mt-3 ">


                          <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-1 ">
                            <table>
                              <tr>
                                <td>
                                  <h2 class="text-lg font-medium text-gray-900 pt-8 ">Mes</h2>
                                </td>

                              </tr>
                              <tr>

                                <td>
                                  <month-picker-input 
                                  :default-year="defaultYear1" 
                                  :default-month="defaultMonth1"
                                    style='z-index:2004'
                                     :lang="selectedLang" 
                                     :min-date="minDate1"
                                    :max-date="maxDate1"
                                    @change="showDate"></month-picker-input>
                                </td>
                              </tr>

                            </table>
                          </div>

                        </div>

                      </div>

                      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1   pl-12">
                        <div class="bg-white    mb-4 mt-3  ">
                          <fieldset>

                            <div class="mt-2 space-y-5">

                              

                              <div>

                                <div class="flex flex-shrink-0  ml-1  py-4 text-center">
                                  <button type="button" @click="loadExportarPdf(false)"
                                    class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Salir</button>
                                  <button type="button" @click="descargaPdf()"
                                    class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    Generar PDF</button>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div v-if="showPanelExportarExcel" style="z-index:2000" class="relative z-10" aria-labelledby="slide-over-title"
      role="dialog" aria-modal="true">
      <!-- Background backdrop, show/hide based on slide-over state. -->
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>


      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden ">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex  pl-10 max-w-full ">
            <!--
                Slide-over panel, show/hide based on slide-over state.

                Entering: "transform transition ease-in-out duration-500 sm:duration-700"
                  From: "translate-x-full"
                  To: "translate-x-0"
                Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
                  From: "translate-x-0"
                  To: "translate-x-full"
              -->
            <div class="pointer-events-auto  w-screen   max-w-4xl">
              <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <div
                      class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                      </svg>
                    </div>
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Exportar datos a excel</h2>

                    <div class="ml-3 flex h-7 items-center">
                      <button @click="loadExportarExcel(false)" type="button"
                        class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                        <span class="sr-only">Close panel</span>
                        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                          aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">

                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div class="absolute inset-0 py-6 pt-8 sm:px-6">
                    <div class="h-full border-2 border-gray-200 " aria-hidden="true">

                      <h2 class="text-lg font-medium text-gray-900 pt-8 " id="slide-over-title">Seleccione un rango de
                        fechas</h2>

                      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 pl-8">

                        <div class="bg-white   align-right mb-4 mt-3 ">



                          <CalendarioFaenas></CalendarioFaenas>



                        </div>

                      </div>

                      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1   pl-8">
                        <div class="bg-white    mb-4 mt-3  ">
                          <fieldset>

                            <div class="mt-2 space-y-5 justify-center">



                              <div>

                                <div class="flex flex-shrink-0 px-4 py-4 text-center">
                                  <button type="button" @click="loadExportarExcel(false)"
                                    class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Salir</button>
                                  <button type="submit"  class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Exportar datos a excel</button>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div v-if="showPanelAyuda" style="z-index:2000" class="relative z-10" aria-labelledby="slide-over-title" role="dialog"
      aria-modal="true">
      <!-- Background backdrop, show/hide based on slide-over state. -->
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>


      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden ">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex  pl-10 max-w-full ">
 
            <div class="pointer-events-auto  w-screen   max-w-2xl">
              <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <div
                      class="inline-flex h-10 w-10 items-center justify-center rounded-md  text-white sm:h-12 sm:w-12">

                    </div>
                    <h2 class="text-1xl font-bold tracking-tight text-white sm:text-4xl"> AYUDA
                      </h2>

                    <div class="ml-3 flex h-7 items-center">
                      <button @click="loadAyuda(false)" type="button"
                        class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                        <span class="sr-only">Close panel</span>
                        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                          aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">

                  </div>
                </div>
                <div class="relative flex-1 py-6 px-2 sm:px-2">
                  <!-- Replace with your content -->
                  <div class="absolute inset-0 py-6 pt-8 sm:px-2">

                    <img src="../../assets/historial.png"/>

                  </div>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>
<script>

import { MonthPickerInput } from 'vue-month-picker'
import CalendarioFaenas from "../calendar/CalendarioFaenas";
export default {
  props: ['nombreFaena'],
  emits: ['loadAAMM'],
  components: {
    MonthPickerInput, CalendarioFaenas
  },
  methods: {
    chgFec1(date) {
    /*  {
    "from": "2023-01-01T03:00:00.000Z",
    "to": "2023-02-01T03:00:00.000Z",
    "month": "Enero",
    "monthIndex": 1,
    "year": 2023,
    "rangeFrom": null,
    "rangeTo": null,
    "rangeFromMonth": null,
    "rangeToMonth": null
}*/
      this.titulo_fecha = date.month + " " + date.year;
      this.$emit("loadAAMM",  date.year + "-" + date.monthIndex );
    },
 
    chgFec2() {
      console.log('hola 2')
    },
    loadExportarPdf(resp) {
      this.showPanelExportar = resp
      this.showPanelExportarExcel = false
   
    },
    descargaPdf(){
      console.log(this.date.monthIndex ,"<---")
    },
    loadExportarExcel(resp) {
      this.showPanelExportarExcel = resp
      this.showPanelExportar = false
    },
    loadAyuda(resp) {
      console.log('ayuda')
      this.showPanelAyuda = resp
      this.showPanelExportar = false
      this.showPanelExportarExcel = false
    },
    showDate(date) {
            this.date = date
        },
  },
  mounted() {

    const fechaActual = new Date();
    this.aa_desde = fechaActual.getFullYear();
    this.mm_desde = fechaActual.getMonth() + 1;
    this.titulo_fecha = this.nomMes[this.mm_desde] + " " + this.aa_desde;
    this.fechaInicial="01"+"-"+this.mm_desde+"-"+this.aa_desde;
  },
  data() {
    return {
      showPanelExportar: false,
      showPanelExportarExcel: false,
      showPanelAyuda: false,
      load: false,
      value: [0],
      step: 1,
      mm_desde: '',
      aa_desde: '',
      mm_hasta: 'Enero',
      aa_hasta: '2023',
      selectedLang: 'es',
      defaultYear1: 2023,
      defaultYear2: 2023,
      defaultMonth1: 12,
      defaultMonth2: 11,
      minDate1: new Date("2022-06-01"),
      maxDate1: new Date("2024-01-01"),
      minDate2: new Date("2022-06-01"),
      maxDate2: new Date("2023-12-01"),
      nomMes: ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      titulo_fecha: '',
      fechaInicial:'',
      date: {
                from: null,
                to: null,
                month: null,
                year: null,
            },
    }
  }
}
</script>
<style src="@vueform/slider/themes/default.css"></style>