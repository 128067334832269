<template>
    <div>
        <ModalHistorialCentroMensual :show="showModal" :seriesFaenas="seriesFaenas" @close="closeModal">
        </ModalHistorialCentroMensual>
        <div class="bg-white m-2 p-2 rounded  col-span-2">
            <div class="md:flex md:items-center md:justify-between">
                <div class="min-w-0 flex-1  text-left pl-8">

                    <h1 class="  text-1xl font-extrabold text-left text-cyan-800">
                        Consumo
                    </h1>
                </div>
                <div class="mt-4 flex md:mt-0 md:ml-4">
                    <!--  <button type="button"
                        class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">?</button>
                    <button type="button"
                    v-on:click="modal"     class="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" >Exportar</button>
                    -->
                    <!--  <button type="button"
                        class="inline-flex
                         items-center 
                         rounded-md
                          border border-gray-300
                           bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">?</button>
                -->
                </div>
            </div>

            <div class="bg-white m-2 p-2 rounded  col-span-2">
                <SpinnerLoading v-show="result_mediciones !== true"></SpinnerLoading>
                <VueApexCharts @click="propagajaula" @mouseover="propagajaula" ref="demoChart" width="100%"
                class="relative z-10"
                    :options="chartOptions" :series="series"></VueApexCharts>
            </div>
        </div>

        <div>

        </div>



    </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
import SpinnerLoading from "../SpinnerLoading"
import ModalHistorialCentroMensual from "./ModalHistorialCentroMensual"

export default {
    props: ['label', 'idJaula', 'dias', 'aammConsulta'],
    emits: ['ampliaGrafico', 'muestraFoto'],
    components: {
        VueApexCharts, SpinnerLoading, ModalHistorialCentroMensual
    },
    data() {
        return {
            arrayIdMeses: ['Sept', 'Oct', 'Nov'],
            aammLocal: '',
            result_mediciones: true,
            nomMes: [
                'Enero', 'Febrero', 'Marzo', 'Abril',
                'Mayo', 'Junio', 'Julio', 'Agosto',
                'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ],
            series: [{
                name: "Acumulado",
                data: [42,  33, 54]
            }, {
                name: "Capacidad Máxima",
                data: [56,41, 56]
            }],
            Acumulado: [],
            CapMax: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 430,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                colors: [ '#4ddda4' , '#3ca0fc' ],
                dataLabels: {
                    enabled: true,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                },
                title: {
                    text: '',
                    align: 'left'
                },
                tooltip: {
                    enabled:false,
                    shared: true,
                    intersect: false
                },
                xaxis: {
                    categories:["Octubre","Noviembre","Diciembre"],
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: "#FF1654",
                        },
                        labels: {
                            style: {
                                colors: "#FF1654",
                            },
                        },
                        title: {
                            text: 'Toneladas de oxígeno',
                            style: {
                                color: "#FF1654",
                            },
                        },
                    },

                ],

            },




        }

    },
    methods: {
        modal() {
            this.showModal = true;
        },
        propagajaula() {
            this.$emit("muestraFoto", this.idJaula, this.label);
        },

        loadDatosMensuales(fec) {
           
        
 
            this.series[0].data = this.seteaSerie(fec, this.Acumulado) // (this.aammConsulta, this.Acumulado)
            this.series[1].data = this.seteaSerie(fec, this.CapMax) // (this.aammConsulta, this.Maxim)
        },
        seteaSerie(mm, objeto) {
            let posicion = this.ubicaDatosMensual(mm, objeto);

            let cont = 0;
            let meses = []
            let mediciones = []
            for (let i = posicion; i >= 0; i--) {
                let paso = objeto[i].fec.split('-')



                meses.push(this.nomMes[paso[1] - 1]);
                cont++;
                mediciones.push(objeto[i].val);
                if (cont == 3) break;
            }
            meses.reverse()
            this.chartOptions = {
                xaxis: { categories: meses }
            }
            return mediciones.reverse()
        },
        ubicaDatosMensual(aamm, objeto) {

            let objetoEncontrado = objeto.find(objeto => objeto.fec === aamm);
            let indiceEncontrado = objeto.indexOf(objetoEncontrado);


            if (objetoEncontrado) {
                console.log('Objeto encontrado:', objetoEncontrado);
                console.log('Objeto indiceEncontrado:', indiceEncontrado);
            } else {
                console.log('No se encontró ningún objeto con fecha:', aamm);
            }
            return indiceEncontrado;
        },
        getdata() {

this.Acumulado.push({ fec: '2022-6', val: '1' })
this.Acumulado.push({ fec: '2022-7', val: '3' })
this.Acumulado.push({ fec: '2022-8', val: '7' })
this.Acumulado.push({ fec: '2022-9', val: '6' })
this.Acumulado.push({ fec: '2022-10', val: '9' })
this.Acumulado.push({ fec: '2022-11', val: '10' })
this.Acumulado.push({ fec: '2022-12', val: '8' })

this.Acumulado.push({ fec: '2023-1', val: '23' })
this.Acumulado.push({ fec: '2023-2', val: '26' })
this.Acumulado.push({ fec: '2023-3', val: '25' })
this.Acumulado.push({ fec: '2023-4', val: '30' })
this.Acumulado.push({ fec: '2023-5', val: '31' })
this.Acumulado.push({ fec: '2023-6', val: '39' })
this.Acumulado.push({ fec: '2023-7', val: '49' })
this.Acumulado.push({ fec: '2023-8', val: '50' })
this.Acumulado.push({ fec: '2023-9', val: '42' })
this.Acumulado.push({ fec: '2023-10', val: '33' })
this.Acumulado.push({ fec: '2023-11', val: '54' })
this.Acumulado.push({ fec: '2023-12', val: '50' })

this.Acumulado.push({ fec: '2024-1', val: '55' })
this.Acumulado.push({ fec: '2024-2', val: '53' })
this.Acumulado.push({ fec: '2024-3', val: '60' })
this.Acumulado.push({ fec: '2024-4', val: '55' })
this.Acumulado.push({ fec: '2024-5', val: '53' })
this.Acumulado.push({ fec: '2024-6', val: '60' })
this.Acumulado.push({ fec: '2024-7', val: '55' })
this.Acumulado.push({ fec: '2024-8', val: '53' })
this.Acumulado.push({ fec: '2024-9', val: '60' })

this.CapMax.push({ fec: '2022-6', val: '3' })
this.CapMax.push({ fec: '2022-7', val: '3' })
this.CapMax.push({ fec: '2022-8', val: '7' })
this.CapMax.push({ fec: '2022-9', val: '7' })
this.CapMax.push({ fec: '2022-10', val: '11' })
this.CapMax.push({ fec: '2022-11', val: '11' })
this.CapMax.push({ fec: '2022-12', val: '11' })

this.CapMax.push({ fec: '2023-1', val: '26' })
this.CapMax.push({ fec: '2023-2', val: '26' })
this.CapMax.push({ fec: '2023-3', val: '26' })
this.CapMax.push({ fec: '2023-4', val: '41' })
this.CapMax.push({ fec: '2023-5', val: '41' })
this.CapMax.push({ fec: '2023-6', val: '41' })
this.CapMax.push({ fec: '2023-7', val: '56' })
this.CapMax.push({ fec: '2023-8', val: '56' })
this.CapMax.push({ fec: '2023-9', val: '56' })
this.CapMax.push({ fec: '2023-10', val: '41' })
this.CapMax.push({ fec: '2023-11', val: '56' })
this.CapMax.push({ fec: '2023-12', val: '56' })



this.CapMax.push({ fec: '2024-1', val: '59' })
this.CapMax.push({ fec: '2024-2', val: '59' })
this.CapMax.push({ fec: '2024-3', val: '62' })
this.CapMax.push({ fec: '2024-4', val: '59' })
this.CapMax.push({ fec: '2024-5', val: '59' })
this.CapMax.push({ fec: '2024-6', val: '62' })
this.CapMax.push({ fec: '2024-7', val: '59' })
this.CapMax.push({ fec: '2024-8', val: '59' })
this.CapMax.push({ fec: '2024-9', val: '62' })
this.result_mediciones = true;


},
        ampliagrafico() {
            console.log('amplia grafico:', this.idJaula, this.label)
            this.$emit("ampliaGrafico", this.idJaula, this.label);
        },
        mesesPrevios(cantidad){
            let meses=[];
            let contador=1;
            const fecActual= new Date();
            let mesActual = fecActual.getMonth();
            while(contador <=cantidad){
                if(mesActual == 0 ){
                    mesActual=11;
                } 
                meses.push(this.nomMes[ mesActual   ])
                contador++;
                mesActual--;
            }
          
            return  meses.reverse();
        }
    },
    created() {

    },
    mounted() {


        this.chartOptions.xaxis.categories=  this.mesesPrevios(3)   




    },
    beforeUnmount() {


    },
    watch: {
        aammConsulta: function (valor) {
            console.log(valor, "valor") // 2023-1
            this.getdata()
         
            let paso =this.aammConsulta.split('-');
            let aa = paso[0];
            let mm = paso[1];
           
            let fec = `${aa}-${mm}`;
            this.loadDatosMensuales(fec)
        },
        
    }
};
</script>