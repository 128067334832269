 
<template>

<div class="bg-white">
  
  <div class="relative isolate px-6 pt-1 lg:px-8">
    <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80" aria-hidden="true">
      <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[rgb(50,50,115)] to-[#e2e6eb] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
    </div>
    <div class="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
      <div class="hidden sm:mb-8 sm:flex sm:justify-center">
        <div class="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
            <img class="mx-auto  h-10 w-auto" src="../../assets/logo_oxytek_transparente.png" alt="Oxytek" />
        </div>
      </div>
      <div class="text-center">
        <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">  Para continuar<br> verifica tu identidad.</h1>
        <p class="mt-6 text-lg leading-8 text-gray-600">
            Introduce el código que te hemos enviado por correo electrónico para verificar tu identidad.
           
        
        </p>
        <p class="mt-6 text-lg leading-8 text-gray-600"> 
            <input    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                             
        </p>
        <p class="mt-6 text-lg leading-8 text-gray-600">
            ¿No te ha llegado el mensaje de correo electrónico? <a href="#" class="font-semibold text-indigo-600">Reenviar código</a>
        </p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <a href="#" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Enviar código por correo electrónico</a>
           
        </div>
      </div>
    </div>

  </div>
</div>
  
</template>
<script>
import axios from "axios";

import router from "../../router";
 
import { mapGetters } from "vuex";
export default {

    name: "FormCambiarPass",
    created() {
        if (!this.user) {
            router.push("/expire");
        }
        this.datosUsuario = {
            idUsuario: this.user.idUsuario,
            nombreUsuario: this.user.nombreUsuario,
            rol: this.user.rol,
            idEmpresa: this.user.idEmpresa,
        };
    },
    data() {
        return {

            password0: "",
            password1: "",
            password2: "",
            showModal: false,
            type0: 'password',
            type1: 'password',
            type2: 'password',
            btnText0: 'Mostrar',
            btnText1: 'Mostrar',
            btnText2: 'Mostrar',
            err_pass: "",
            err_pass_0:"",
            err_pass_1:"",
            err_pass_2:"",
        };
    },
    components: {
        
    },
    methods: {
        return_home() {
            router.push('/home')
        },
        updModal() {
            this.showModal = true;
        },
        showPassword0() {
            if (this.type0 === 'password') {
                this.type0 = 'text'
                this.btnText0 = 'Ocultar'
            } else {
                this.type0 = 'password'
                this.btnText0 = 'Mostrar'
            }
        },
        showPassword1() {
            if (this.type1 === 'password') {
                this.type1 = 'text'
                this.btnText1 = 'Ocultar'
            } else {
                this.type1 = 'password'
                this.btnText1 = 'Mostrar'
            }
        },
        showPassword2() {
            if (this.type2 === 'password') {
                this.type2 = 'text'
                this.btnText2 = 'Ocultar'
            } else {
                this.type2 = 'password'
                this.btnText2 = 'Mostrar'
            }
        },
        validaPassword() {
            if (this.password0.length == 0) {
                this.err_pass_0 = "*"
                this.err_pass = 'Password original requerida'
                return false;
            }
            if (this.password1.length == 0) {
                this.err_pass_1 = "*"
                this.err_pass = 'Ingrese nueva password'
                return false;
            }
            if (this.password1 !== this.password2) {
                this.err_pass_1 = "*"
                this.err_pass_2 = "*"
                this.err_pass = 'passwords no coinciden'
                return false;
            }


            var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
            if (regex.test(this.password1)) {
                console.log('formato permitido')
                this.err_pass = ''
                console.log('ok!');
                return true;
            } else {
                this.err_pass = "8 caracteres. al menos un numero, una mayúscula y un caracter especial"
            }
            return false;
        },


        handleSubmit() {
            if (this.validaPassword()) {
                this.update()
            }
        },
        async update() {
            try {
                 this.err_pass_0 = "*"
                  this.err_pass_1 = "*"
                   this.err_pass_2 = "*"
               
                const resp = await axios.post(
                    "http://localhost:8080/api/Usuarios/CambiaPass",
                    {
                        userId: this.datosUsuario.idUsuario,
                        nuevoPass: this.password1,
                    }
                );

                switch (resp.status) {
                    case 200:
                        console.log(resp);

                        router.push("/home");
                        break;
                }
            } catch (err) {

                console.log(err);
            }
        },
        async chkpass(){
              try {
                    this.err_pass_0=this.err_pass =""
                    const resp = await axios.post(
                    "http://localhost:8080/api/Usuarios/Login",
                    {
                        email: this.user.email,
                        pass: this.password0,
                    } );

                    switch (resp.status) {
                            case 200:
                                console.log(resp);
                                break;
                    }
                } catch (err) {
                     this.err_pass = "password actual es incorrecta"
                     this.err_pass_0 = "*"
                    console.log(err);
                }
        }
    },
    computed: {
        ...mapGetters(["user"]),
    }
};
</script>
