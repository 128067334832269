<template>
  
    <div v-if="show" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
  
      </div>
  
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
  
          <div
            class="relative transform overflow-hidden rounded-lg bg-white px-0 pt-0 pb-0 text-left shadow-xl transition-all sm:my-0 xs:w-full sm:w-full sm:max-w-7xl sm:p-0">
  
            <div>
              <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
              <div class="relative z-40 md:hidden" role="dialog" aria-modal="true">
  
                <div class="fixed inset-0 bg-gray-600 bg-opacity-75"></div>
  
                <div class="fixed inset-0 z-40 flex">
                  <!--
          Off-canvas menu, show/hide based on off-canvas menu state.
  
          Entering: "transition ease-in-out duration-300 transform"
            From: "-translate-x-full"
            To: "translate-x-0"
          Leaving: "transition ease-in-out duration-300 transform"
            From: "translate-x-0"
            To: "-translate-x-full"
        -->
                  <div class="relative flex w-full max-w-xs flex-1 flex-col bg-indigo-700 pt-5 pb-4">
                    <!--
            Close button, show/hide based on off-canvas menu state.
  
            Entering: "ease-in-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in-out duration-300"
              From: "opacity-100"
              To: "opacity-0"
          -->
                    <div class="absolute top-0 right-0 -mr-12 pt-2">
  
                    </div>
  
                    <div class="flex flex-shrink-0 items-center px-4">
  
                    </div>
  
                  </div>
  
                  <div class="w-14 flex-shrink-0" aria-hidden="true">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                  </div>
                </div>
              </div>
  
              <!-- Static sidebar for desktop -->
              <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex flex-grow flex-col overflow-y-auto bg-indigo-700 pt-5">
                  <div class="flex flex-shrink-0 items-center px-2 text-white">
                    Consumo de oxigeno
                  </div>
                  <div class="mt-5 flex flex-1 flex-col">
                    <nav class="flex-1 space-y-1 px-2 pb-4">
  
                      <!-- Current: "bg-indigo-800 text-white", Default: "text-indigo-100 hover:bg-indigo-600" -->
                      <a href="#"
                        class="bg-indigo-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                        <!-- Heroicon name: outline/home -->
                        <svg class="mr-3 h-6 w-6 flex-shrink-0 text-indigo-300" xmlns="http://www.w3.org/2000/svg"
                          fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                        </svg>
                        Mensual
                      </a>
  
                      <a href="#"
                        class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                        <!-- Heroicon name: outline/users -->
                        <svg class="mr-3 h-6 w-6 flex-shrink-0 text-indigo-300" xmlns="http://www.w3.org/2000/svg"
                          fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                        </svg>
                        Periodo
                      </a>
  
                      <a href="#"
                        class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                        <!-- Heroicon name: outline/folder -->
                        <svg class="mr-3 h-6 w-6 flex-shrink-0 text-indigo-300" xmlns="http://www.w3.org/2000/svg"
                          fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                        </svg>
                        Diario
                      </a>
  
  
  
                    </nav>
                  </div>
                </div>
              </div>
              <div class="flex flex-1 flex-col md:pl-64">
                <span class="text-right"> <a @click.self="close_modal()">Cerrar </a></span>
                <main>
                  <div class="py-6">
                    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                    
                    </div>
  
                    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                      <!-- Replace with your content -->
                      <div class="py-4">
                        <div class="h-96 rounded-lg border-4 border-dashed border-gray-200">
  
                          <apexchart width="100%" height="350" type="bar" :options="options" :series="series">
                          </apexchart>
                        </div>
                      </div>
                      <!-- /End replace -->
                    </div>
                  </div>
                </main>
              </div>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  
  
  </template>
  
  
  <script>
  import VueApexCharts from "vue3-apexcharts";
 
  
  
  export default {
    components: {
      apexchart: VueApexCharts,
      
    },
    name: "ModalPeriodoConsumoOxigeno",
    create: {
  
    },
    props: ["show", "mensaje", "seriesFaenas"],
  
    data: () => ({
        visible:false,
      options: {
        chart: {
  
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [
            "Nov 2022",
            "Dic 2022",
            "Ene 2023",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
  
          ]
        },
        yaxis: {
          title: {
            text: ' Toneladas'
          }
        },
        tooltip: {
                y: {
                  formatter: function (val) {
                    return   val + " Toneladas "
                  }
                }
              }
      },
      series: [{
        name: 'Tren de Jaulas A',
        data: [124.1, 125, 93.9]
      },
      {
        name: 'Tren de Jaulas B',
        data: [125, 94.3, 124.2]
      }]
    }),
  
  
  
    methods: {
      delete_usuario() {
  
        this.$emit("delete")
  
      },
      close_modal() {
        console.log('cerrar modal 1');
        this.$emit("close")
      }
  
    }
  }
  </script>